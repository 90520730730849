import styles from "@/components/about-popup.module.css";
import LogoBox from "@/components/logo-box";
import PopupShell from "@/components/popup-shell";
import { globals } from "@/globals";
import { closePopup } from "@/utils";
import { t } from "i18next";
import { JSX, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

interface AboutPopupProps {
	isOpen: boolean;
}

function AboutPopup({ isOpen }: AboutPopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeAboutPopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("about");
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeAboutPopup}
		>
			<div className="popup-content universal-container">
				<LogoBox className={styles["about-logo-box"]} />
				<div className={styles["about-text"]}>
					<h1>
						{globals.APP_NAME +
							" " +
							(globals.isApp ? t("desktopApp") : t("webApp")) +
							" " +
							globals.VERSION}
					</h1>
					<div
						dangerouslySetInnerHTML={{
							__html: t("developedBy", {
								name1: renderToStaticMarkup(
									<a
										className="link"
										href="https://www.yangshangzhen.com/"
										target="_blank"
										rel="noreferrer"
									>
										{t("shangzhenYang")}
									</a>,
								),
								name2: renderToStaticMarkup(
									<a
										className="link"
										href="https://maorx.cn/"
										target="_blank"
										rel="noreferrer"
									>
										{t("ruoxinMao")}
									</a>,
								),
							}),
						}}
					></div>
					<div>
						&copy; 2018-{new Date().getFullYear()}{" "}
						<a
							className="link"
							href={globals.home}
							target="_blank"
							rel="noreferrer"
						>
							{t("fuzhouRetieheSoftwareCoLtd")}
						</a>
					</div>
					<div>
						<a
							className="link"
							href="https://docs.retiehe.com/tos.html"
							target="_blank"
							rel="noreferrer"
						>
							{t("termsOfService")}
						</a>
						<span className="text-divider"></span>
						<a
							className="link"
							href="https://docs.retiehe.com/privacy.html"
							target="_blank"
							rel="noreferrer"
						>
							{t("privacyPolicy")}
						</a>
					</div>
				</div>
			</div>
		</PopupShell>
	);
}

export default AboutPopup;
