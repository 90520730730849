import * as api from "@/api";
import LocalWallpaper from "@/classes/local-wallpaper";
import PopupShell from "@/components/popup-shell";
import SettingsItem from "@/components/settings-item";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import {
	closePopup,
	handleThemeChange,
	logIn,
	mergeSettings,
	openAccount,
	showDialog,
	sleep,
} from "@/utils";
import i18next, { t } from "i18next";
import { ChangeEvent, JSX, useRef, useState } from "react";

interface SettingsPopupProps {
	isOpen: boolean;
}

function SettingsPopup({ isOpen }: SettingsPopupProps): JSX.Element {
	const [downloadPath, setDownloadPath] = useState<string>(
		desktopApp.getDownloadPath(),
	);
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [isDark, setIsDark] = useState<boolean>(globals.isDark);
	const [language, setLanguage] = useState<string>(i18next.language);

	const wallpaperInputRef = useRef<HTMLInputElement>(null);

	const closeSettingsPopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("settings");
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleWallpaperChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];
		if (!file) {
			return;
		}
		const localWallpaper = LocalWallpaper.getInstance();
		void localWallpaper.saveWallpaper(file);
	};

	const showSettingsSaved = (): void => {
		signals.toast.value = {
			text: t("settingsSaved"),
			timeout: 1500,
		};
	};

	const settingsItems = [
		{
			label: t("language"),
			options: [
				{
					label: "简体中文",
					value: "zh-CN",
				},
				{
					label: "繁體中文",
					value: "zh-TW",
				},
				{
					label: "English",
					value: "en-US",
				},
			],
			setValue: async (newValue: string): Promise<void> => {
				setLanguage(newValue);
				localStorage.setItem("Language", newValue);
				await showDialog(t("refreshToApplySettings"), {
					showCancel: true,
				});
				window.location.reload();
			},
			value: language,
			when: true,
		},
		{
			label: t("server"),
			options: Object.keys(signals.servers.value).map((key) => {
				return {
					label: signals.servers.value[key].name,
					value: key,
				};
			}),
			setValue: async (newValue: string): Promise<void> => {
				if (!signals.login.value.username) {
					logIn();
					return;
				}
				if (signals.premiumData.value <= 0) {
					await showDialog(t("premiumRequiredForSwitchingServer"), {
						showCancel: true,
					});
					openAccount("premium");
					return;
				}
				signals.selectedServer.value = newValue;
				globals.dynamicInfo.selectedServer = newValue;
			},
			value: signals.selectedServer.value,
			when: true,
		},
		{
			checked: signals.settings.value.loginRequired,
			label: t("requireLoginWhenReceivingMyFiles"),
			setChecked: async (newValue: boolean): Promise<void> => {
				if (!signals.login.value.username) {
					logIn();
					mergeSettings({
						loginRequired: false,
					});
					return;
				}
				mergeSettings({
					loginRequired: newValue,
				});
				signals.isLoadingShown.value = true;
				try {
					await api.setSettings("loginRequired", newValue.toString());
					showSettingsSaved();
				} catch (error) {
					api.handleApiError(error);
				} finally {
					signals.isLoadingShown.value = false;
				}
			},
			when: true,
		},
		{
			checked: isDark,
			label: t("darkMode"),
			setChecked: (newValue: boolean): void => {
				setIsDark(newValue);
				globals.isDark = newValue;
				if (newValue) {
					localStorage.setItem("Theme", "Dark");
				} else {
					localStorage.setItem("Theme", "Light");
				}
				handleThemeChange();
				showSettingsSaved();
			},
			when: true,
		},
		{
			label: t("customWallpaper"),
			options: [
				{
					label: t("none"),
					value: "none",
				},
				{
					label: t("localImage"),
					value: "localImage",
				},
				{
					label: t("onlineImageUrl"),
					value: "onlineImageUrl",
					when: !globals.isApp,
				},
			],
			setValue: async (newValue: string): Promise<void> => {
				if (newValue !== "none") {
					if (!signals.login.value.username) {
						if (globals.isIos || globals.isSafari) {
							// Requires user interaction
							await showDialog(t("youHaveNotLoggedIn"), {
								showCancel: true,
							});
						}
						logIn();
						return;
					}
					if (signals.premiumData.value <= 0) {
						await showDialog(
							t("premiumRequiredForUsingCustomWallpaper"),
							{
								showCancel: true,
							},
						);
						openAccount("premium");
						return;
					}
				}
				if (newValue === "localImage") {
					if (globals.isIos || globals.isSafari) {
						// Requires user interaction
						await showDialog(t("selectFile"));
					}
					wallpaperInputRef.current?.click();
					return;
				}
				if (newValue === "onlineImageUrl") {
					const url = await showDialog(t("enterOnlineImageUrl"), {
						isPrompt: true,
						placeholder: "https://",
						showCancel: true,
					});
					if (!url?.startsWith("https://")) {
						await sleep(globals.ANIMATION_WAIT_TIME * 2);
						void showDialog(t("urlMustStartWithHttps"), {
							title: t("error"),
						});
						return;
					}
					localStorage.setItem("Wallpaper", url);
					document.documentElement.classList.add("has-wallpaper");
					signals.wallpaperUrl.value = url;
				} else {
					localStorage.removeItem("Wallpaper");
					document.documentElement.classList.remove("has-wallpaper");
					signals.wallpaperUrl.value = null;
				}
				const localWallpaper = LocalWallpaper.getInstance();
				void localWallpaper.deleteWallpaper();
			},
			value: ((): string => {
				if (!signals.wallpaperUrl.value) {
					return "none";
				} else if (signals.wallpaperUrl.value.startsWith("blob:")) {
					return "localImage";
				} else {
					return "onlineImageUrl";
				}
			})(),
			when: true,
		},
		{
			button: t("change"),
			label: t("fileSaveLocation"),
			note: downloadPath,
			onClick: async (): Promise<void> => {
				const newPath = await desktopApp.selectDirectory();
				setDownloadPath(newPath);
				localStorage.setItem("DownloadLocation", newPath);
				showSettingsSaved();
			},
			when: desktopApp.isElectron,
		},
	];

	const settingsElements = settingsItems.map((item) => {
		return (
			<SettingsItem
				key={item.label}
				{...item}
			/>
		);
	});

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeSettingsPopup}
		>
			<div className="popup-content universal-container">
				<h1 className="popup-title">{t("settings")}</h1>
				<div className="content-left-aligned">{settingsElements}</div>
			</div>
			<input
				accept="image/*"
				hidden={true}
				onChange={handleWallpaperChange}
				ref={wallpaperInputRef}
				type="file"
			/>
		</PopupShell>
	);
}

export default SettingsPopup;
