import Receiver from "@/classes/receiver";
import Countdown from "@/components/countdown";
import MyFiles from "@/components/my-files";
import PopupShell from "@/components/popup-shell";
import Progress from "@/components/progress";
import ReceiveCodeInput from "@/components/receive-code-input";
import ReceiveFileList from "@/components/receive-file-list";
import ReceivePasswordInput from "@/components/receive-password-input";
import Slides from "@/components/slides";
import TextArea from "@/components/text-area";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { ReceivePage, TextAreaPage } from "@/types";
import { clearPathname, closePopup } from "@/utils";
import { t } from "i18next";
import { JSX, useCallback, useRef, useState } from "react";

interface ReceivePopupProps {
	isOpen: boolean;
}

function ReceivePopup({ isOpen }: ReceivePopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const codeInputRef = useRef<HTMLInputElement>(null);
	const passwordInputRef = useRef<HTMLInputElement>(null);

	const closeReceivePopup = useCallback((): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("receive");
			signals.code.value = "";
			signals.currentFile.value = null;
			signals.password.value = "";
			signals.progress.value = null;
			signals.receivedFiles.value = [];
			signals.receivePage.value = ReceivePage.CODE;
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
		clearPathname();
	}, []);

	const receiveFile = (code: string): void => {
		void new Receiver(
			signals.premiumData.value > 0,
			codeInputRef,
			passwordInputRef,
		).receiveFile(code, signals.password.value);
	};

	return (
		<PopupShell
			countdown={
				signals.receivePage.value === ReceivePage.FILE_LIST ? (
					<Countdown
						callback={closeReceivePopup}
						seconds={120}
					/>
				) : undefined
			}
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={
				signals.receivePage.value === ReceivePage.PROGRESS
					? undefined
					: closeReceivePopup
			}
		>
			<Slides currentPage={signals.receivePage.value}>
				<MyFiles receiveFile={receiveFile} />
				<ReceiveCodeInput
					inputRef={codeInputRef}
					receiveFile={receiveFile}
				/>
				<ReceivePasswordInput
					inputRef={passwordInputRef}
					receiveFile={receiveFile}
				/>
				<ReceiveFileList />
				<Progress title={t("downloading")} />
				<TextArea currentPage={TextAreaPage.TEXT_RECEIVED} />
				<TextArea currentPage={TextAreaPage.REPORT} />
			</Slides>
		</PopupShell>
	);
}

export default ReceivePopup;
