import Downloader from "@/classes/downloader";
import LivePhotoIcon from "@/components/live-photo-icon";
import styles from "@/components/receive-file-list.module.css";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { ReceivePage } from "@/types";
import { getFileIcon, logIn } from "@/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, MouseEvent, useEffect } from "react";

function ReceiveFileList(): JSX.Element {
	const filenamesSet = new Set<string>();
	for (const file of signals.receivedFiles.value) {
		if (!file.name) {
			continue;
		}
		filenamesSet.add(file.name.toLowerCase());
	}

	const dlWarnFile = t("dlWarnFile", {
		extra:
			globals.dynamicInfo.executable &&
			signals.receivedFiles.value[0]?.name &&
			new RegExp(globals.dynamicInfo.executable).test(
				signals.receivedFiles.value[0].name,
			)
				? `<span class="emphasize">${t("executableFileReceived")}</span>`
				: "",
		region: signals.receivedFiles.value[0]?.region
			? `<span class="box">IP 属地${signals.receivedFiles.value[0].region}</span>的`
			: "",
		reportToUs: `<a class="report-link" href="#">${t("reportToUs")}</a>`,
	});

	const downloadAll = (): void => {
		void new Downloader(
			signals.receivedFiles.value,
			signals.premiumData.value > 0,
		).downloadFiles();
	};

	const getLivePhotoVideoByImage = (filename?: string): string | null => {
		if (!filename) {
			return null;
		}
		const filenameLowered = filename.toLowerCase();
		if (!filenameLowered.endsWith(".pvt.jpeg")) {
			return null;
		}
		const videoFilename = filenameLowered.replace(".pvt.jpeg", ".pvt.mov");
		if (!filenamesSet.has(videoFilename)) {
			return null;
		}
		return videoFilename;
	};

	const isLivePhotoImage = (filename?: string): boolean => {
		if (!filename) {
			return false;
		}
		if (
			/^([^\s/\\][^/\\]*MP)\.(JPG|jpg|JPEG|jpeg|HEIC|heic|AVIF|avif)/.test(
				filename,
			)
		) {
			return true;
		}
		if (!globals.isApp || !globals.isWindows) {
			return false;
		}
		return !!getLivePhotoVideoByImage(filename);
	};

	const isLivePhotoVideo = (filename?: string): boolean => {
		if (!filename || !globals.isApp || !globals.isWindows) {
			return false;
		}
		const filenameLowered = filename.toLowerCase();
		return (
			filenameLowered.endsWith(".pvt.mov") &&
			filenamesSet.has(filenameLowered.replace(".pvt.mov", ".pvt.jpeg"))
		);
	};

	const fileElements = signals.receivedFiles.value.map((file, index) => {
		if (isLivePhotoVideo(file.name)) {
			return null;
		}

		const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
			if (!desktopApp.isElectron) {
				return;
			}
			event.preventDefault();
			const filesInfo = [file];
			if (globals.isWindows) {
				const livePhotoVideoFilename = getLivePhotoVideoByImage(
					file.name,
				);
				const livePhotoVideo = signals.receivedFiles.value.find(
					(item) =>
						item.name?.toLowerCase() === livePhotoVideoFilename,
				);
				if (livePhotoVideo) {
					filesInfo.push(livePhotoVideo);
				}
			}
			void new Downloader(
				filesInfo,
				signals.premiumData.value > 0,
			).downloadFiles();
		};

		return (
			<a
				className="interactive"
				href={file.download?.[0] || "#"}
				key={index}
				target="_blank"
				rel="noreferrer"
				onClick={handleClick}
			>
				<div className={styles["file-name-container"]}>
					<FontAwesomeIcon
						className="file-icon"
						fixedWidth
						icon={getFileIcon(file.type)}
					/>
					{file.name && decodeURIComponent(file.name)}
				</div>
				{isLivePhotoImage(file.name) && <LivePhotoIcon />}
			</a>
		);
	});

	useEffect(() => {
		const reportLinks = document.getElementsByClassName(
			"report-link",
		) as HTMLCollectionOf<HTMLAnchorElement>;
		for (const reportLink of reportLinks) {
			reportLink.onclick = (event): void => {
				event.preventDefault();
				if (!signals.login.value.username) {
					logIn();
					return;
				}
				signals.receivePage.value = ReceivePage.REPORT;
			};
		}
	}, []);

	return (
		<div className={clsx("universal-container", styles["container"])}>
			<div className={clsx("popup-title", styles["popup-title"])}>
				{t("filesReceived")}
			</div>
			<p className={styles["description"]}>
				{t("clickToDownloadSeparately")}
			</p>
			<div className={clsx("file-list", styles["file-list"])}>
				<div
					className="warning"
					dangerouslySetInnerHTML={{ __html: dlWarnFile }}
				></div>
				{fileElements}
			</div>
			<button
				className="popup-main-button"
				type="button"
				onClick={downloadAll}
			>
				{t("downloadAll")}
			</button>
		</div>
	);
}

export default ReceiveFileList;
