import * as api from "@/api";
import Downloader from "@/classes/downloader";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { ReceivePage } from "@/types";
import {
	logIn,
	requireApp,
	showCaptcha,
	showDialog,
	showResponseDialog,
} from "@/utils";
import { t } from "i18next";
import { RefObject } from "react";

class Receiver {
	private readonly hasPremium: boolean;
	private readonly codeInputRef?: RefObject<HTMLInputElement | null>;
	private readonly passwordInputRef?: RefObject<HTMLInputElement | null>;

	public constructor(
		hasPremium: boolean,
		codeInputRef?: RefObject<HTMLInputElement | null>,
		passwordInputRef?: RefObject<HTMLInputElement | null>,
	) {
		this.hasPremium = hasPremium;
		this.codeInputRef = codeInputRef;
		this.passwordInputRef = passwordInputRef;
	}

	private async fileNotExists(text = t("thisFileNotExist")): Promise<void> {
		signals.invalidCodeAttempts.value++;
		signals.code.value = "";
		await showDialog(text);
		this.codeInputRef?.current?.focus();
	}

	public static isCodeInvalid(code: string): boolean {
		const codeNumber = parseInt(code);
		return isNaN(codeNumber) || codeNumber < 1000 || codeNumber > 99999999;
	}

	public async receiveFile(
		code: string,
		password?: string,
		extraAction = "previewCost",
	): Promise<void> {
		if (Receiver.isCodeInvalid(code)) {
			void this.fileNotExists();
			return;
		}
		const action = "receive" + (password ? "WithPassword" : "");
		const captchaResponse = await showCaptcha(action);
		signals.isLoadingShown.value = true;
		try {
			const data = await api.receiveFile(
				code,
				password,
				captchaResponse,
				extraAction,
			);
			signals.isLoadingShown.value = false;
			const responseIsArray = Array.isArray(data);
			if (!responseIsArray && data.error) {
				switch (data.error) {
					case "appRequired": {
						void requireApp(data.alert || data.error);
						break;
					}
					case "captchaRequired": {
						if (!globals.captchaRequiredFor.has(action)) {
							globals.captchaRequiredFor.add(action);
							void this.receiveFile(code, password, extraAction);
						} else {
							void showDialog(data.alert || data.error);
						}
						break;
					}
					case "loginRequired": {
						if (signals.login.value.username) {
							void showDialog(
								t("noPermissionToDownloadThisFile"),
							);
						} else {
							await showDialog(
								t("loginRequiredForDownloadingThisFile"),
								{
									showCancel: true,
								},
							);
							logIn();
						}
						break;
					}
					case "notExist": {
						void this.fileNotExists(data.alert);
						break;
					}
					case "passwordIncorrect": {
						signals.password.value = "";
						await showDialog(t("incorrectPassword"));
						this.passwordInputRef?.current?.focus();
						break;
					}
					case "passwordRequired": {
						signals.receivePage.value = ReceivePage.PASSWORD;
						break;
					}
					case "requireConfirm": {
						await showDialog(data.alert || data.error, {
							showCancel: true,
						});
						void this.receiveFile(
							code,
							password,
							data.nextAction || "",
						);
						break;
					}
					default: {
						void showResponseDialog(
							data.alert || data.error,
							data.link,
						);
						break;
					}
				}
			} else if (!responseIsArray && data.text) {
				signals.currentFile.value = {
					code: code,
					region: data.region,
					text: data.text,
				};
				signals.receivePage.value = ReceivePage.TEXT_RECEIVED;
			} else if (responseIsArray) {
				if (data.length === 1 && data[0].isOwner) {
					void new Downloader(data, this.hasPremium).downloadFiles();
				} else {
					signals.currentFile.value = {
						code: code,
						region: data[0].region,
					};
					signals.receivedFiles.value = data;
					signals.receivePage.value = ReceivePage.FILE_LIST;
				}
			}
		} catch (error) {
			signals.isLoadingShown.value = false;
			api.handleApiError(error);
		}
	}
}

export default Receiver;
