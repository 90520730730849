import styles from "@/components/switch.module.css";
import { ChangeEventHandler, JSX, useId } from "react";

interface SwitchProps {
	checked?: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
	title: string;
}

function Switch({ checked, onChange, title }: SwitchProps): JSX.Element {
	const id = useId();

	return (
		<label
			aria-label={title}
			className={styles["switch"]}
			htmlFor={id}
		>
			<input
				checked={checked}
				id={id}
				onChange={onChange}
				type="checkbox"
			/>
			<span className={styles["slider"]}></span>
		</label>
	);
}

export default Switch;
