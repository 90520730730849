import { DynamicInfo } from "@/types";
import { handleThemeChange } from "@/utils";
import { MouseEvent } from "react";

export const globals = {
	ANIMATION_WAIT_TIME: 250 as const,
	APP_NAME: "AirPortal" as const,
	BACKEND: process.env.RTH_BACKEND,
	BACKEND_CENTRAL: "https://api.retiehe.com/backend",
	captchaCallback: null as
		| ((captchaResponse: CaptchaResponse) => void)
		| null,
	captchaRequiredFor: new Set<string>(),
	closedTips: new Set<string>(),
	dialogCallbacks: {} as Record<
		string,
		(event?: MouseEvent, inputValue?: string) => void
	>,
	dynamicInfo: {} as DynamicInfo,
	filesSentCount: 0,
	FRONTEND: "https://www.airportal.cn/" as const,
	home: "https://www.retiehe.com/",
	isAndroid: navigator.userAgent.includes("Android"),
	isApp:
		navigator.userAgent.includes("Electron") &&
		!navigator.userAgent.includes("Cypress"),
	isBeta: window.location.hostname === "beta.airportal.cn",
	isChinaTimeZone:
		Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Shanghai",
	isDark: ((): boolean => {
		let theme: string | null = null;
		try {
			theme = localStorage.getItem("Theme");
		} catch (error) {
			console.error(error);
		}
		if (!theme) {
			const themeMatch = matchMedia("(prefers-color-scheme: dark)");
			themeMatch.onchange = (event): void => {
				if (localStorage.getItem("Theme")) {
					return;
				}
				globals.isDark = event.matches;
				handleThemeChange();
			};
			return themeMatch.matches;
		} else if (theme) {
			return theme === "Dark";
		}
		return false;
	})(),
	isHarmony: navigator.userAgent.includes("OpenHarmony"),
	isIos: false,
	isLinux: navigator.userAgent.includes("Linux"),
	isMac: navigator.userAgent.includes("Macintosh"),
	isMobile: false,
	isPwa: window.matchMedia("(display-mode: standalone)").matches,
	isSafari:
		navigator.userAgent.includes("Safari") &&
		!navigator.userAgent.includes("Chrome"),
	isTencent:
		navigator.userAgent.includes("MicroMessenger") ||
		navigator.userAgent.includes("QQ") ||
		navigator.userAgent.includes("SE 2.X MetaSr 1.0"),
	isWindows: navigator.userAgent.includes("Windows"),
	MB: 1048576 as const,
	notificationCallbacks: {
		onClick: null as (() => void) | null,
		onClose: null as (() => void) | null,
	},
	openedLoginPage: null as Window | null,
	params: Object.fromEntries(
		new URLSearchParams(window.location.search).entries(),
	),
	pendingUploadFiles: [] as File[],
	PREMIUM_REQUIRED_FILE_SIZE: 2147483648 as const, // 2 GB
	privateIp: "",
	sendCallback: null as
		| ((senderCode?: string) => void | Promise<void>)
		| null,
	VERSION: process.env.VERSION || "",
	wifiTransferIp: "",
};

if (globals.isApp || globals.isPwa) {
	document.documentElement.classList.add("app");
}
globals.isIos =
	navigator.userAgent.includes("iPhone") ||
	navigator.userAgent.includes("iPad") ||
	(globals.isMac && navigator.maxTouchPoints > 0);
globals.isMobile = globals.isAndroid || globals.isIos || globals.isHarmony;
handleThemeChange();
