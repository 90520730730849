import * as api from "@/api";
import ActionButtonBar from "@/components/action-button-bar";
import Countdown from "@/components/countdown";
import PopupShell from "@/components/popup-shell";
import styles from "@/components/sender-code-popup.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { closePopup, copyText, showResponseDialog } from "@/utils";
import {
	faCopy,
	faRefresh,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useCallback, useEffect, useState } from "react";

const PLACEHOLDER = "------";

interface SenderCodePopupProps {
	getRecentFile: () => Promise<void>;
	isOpen: boolean;
}

function SenderCodePopup({
	getRecentFile,
	isOpen,
}: SenderCodePopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [senderCode, setSenderCode] = useState<string>(PLACEHOLDER);

	const closeSenderCodePopup = (): void => {
		api.deleteSenderCode()
			.then(() => {
				signals.toast.value = {
					text: t("senderCodeInvalidated"),
				};
			})
			.catch(console.error);
		void getRecentFile();
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("senderCode");
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const createSenderCode = useCallback(async (): Promise<void> => {
		setSenderCode(PLACEHOLDER);
		signals.isLoadingShown.value = true;
		try {
			const data = await api.createSenderCode();
			if (data.error) {
				void showResponseDialog(data.alert || data.error, data.link);
				return;
			} else if (data.code) {
				setSenderCode(data.code.toString());
			}
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingShown.value = false;
		}
	}, []);

	const actionButtons = [
		{
			attributes: {
				onClick: (): void => {
					void copyText(senderCode);
				},
			},
			icon: faCopy,
			label: t("copy"),
		},
		{
			attributes: {
				onClick: (): void => {
					void createSenderCode();
				},
			},
			icon: faRefresh,
			label: t("refresh"),
		},
	];

	useEffect(() => {
		if (!isOpen) {
			return;
		}
		void createSenderCode();
	}, [createSenderCode, isOpen]);

	return (
		<PopupShell
			countdown={
				<Countdown
					callback={closeSenderCodePopup}
					seconds={180}
					seed={senderCode}
				/>
			}
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeSenderCodePopup}
		>
			<div className="popup-content universal-container">
				<h1 className="popup-title">{t("yourSenderCode")}</h1>
				<div
					className={clsx("universal-container", styles["container"])}
				>
					<div className={clsx("code", "selectable", styles["code"])}>
						{senderCode}
					</div>
					<div className={clsx("tip", styles["tip"])}>
						{t("expiresAfterOneUse")}
					</div>
					<div className={clsx("warning", styles["warning"])}>
						<FontAwesomeIcon icon={faWarning} />
						{t("senderCodeWarning")}
					</div>
					<ActionButtonBar buttons={actionButtons} />
				</div>
			</div>
		</PopupShell>
	);
}

export default SenderCodePopup;
