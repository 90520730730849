import styles from "@/components/receive-code-input.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { SendPage } from "@/types";
import { showDialog } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, JSX, useId } from "react";

function SenderCodeInput(): JSX.Element {
	const id = useId();

	const handleInvalidCode = async (): Promise<void> => {
		signals.invalidSenderCodeAttempts.value += 1;
		signals.senderCode.value = "";
		await showDialog(t("senderCodeInvalidOrExpired"));
		signals.sendPage.value = SendPage.ID_SELECTOR;
	};

	const handleSenderCodeChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		signals.senderCode.value = event.target.value;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		const senderCodeNumber = Number(signals.senderCode);
		if (
			isNaN(senderCodeNumber) ||
			senderCodeNumber < 100000 ||
			senderCodeNumber > 999999
		) {
			void handleInvalidCode();
			return;
		}
		void globals.sendCallback?.(signals.senderCode.value);
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterSenderCode")}</label>
				<div className="tip">
					{t("getSenderCodeOnYourOtherLoggedInDevice")}
				</div>
			</div>
			<div>
				<input
					autoFocus
					className={clsx("input", styles["receive-input"])}
					id={id}
					type="tel"
					autoComplete="off"
					inputMode="numeric"
					value={signals.senderCode.value}
					onChange={handleSenderCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default SenderCodeInput;
