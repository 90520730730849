import styles from "@/components/wallpaper.module.css";
import * as signals from "@/signals";
import { JSX, MouseEvent } from "react";

function Wallpaper(): JSX.Element {
	const handleContextMenu = (event: MouseEvent<HTMLImageElement>): void => {
		event.preventDefault();
		signals.isMenuShown.value = true;
	};

	if (signals.premiumData.value <= 0 || !signals.wallpaperUrl.value) {
		return <></>;
	}
	return (
		<img
			alt=""
			className={styles["wallpaper"]}
			onContextMenu={handleContextMenu}
			src={signals.wallpaperUrl.value}
		/>
	);
}

export default Wallpaper;
