import ActionButtonBar from "@/components/action-button-bar";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { SendPage } from "@/types";
import { copyText } from "@/utils";
import { faLink, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { JSX, useEffect } from "react";

function SendSuccess(): JSX.Element {
	const url =
		(globals.dynamicInfo.downloadLink || globals.FRONTEND) +
		signals.sendSuccessInfo.value?.code +
		"/" +
		signals.sendSuccessInfo.value?.key;

	const actionButtons = [
		{
			attributes: {
				"data-clipboard-text": url, // for cypress testing
				"onClick": (): void => {
					void copyText(`${url} ${t("copyLinkIntoBrowserToOpen")}`);
				},
			},
			icon: faLink,
			label: t("copyDownloadLink"),
		},
		{
			attributes: {
				onClick: (): void => {
					signals.qrCode.value = url;
					signals.sendPage.value = SendPage.QR_CODE;
				},
			},
			icon: faQrcode,
			label: t("showQrCode"),
		},
	];

	useEffect(() => {
		if (!signals.sendSuccessInfo.value?.code) {
			return;
		}
		document.title = `[${t("code")} ${signals.sendSuccessInfo.value.code}] ${t("airportalTitle")}`;
		return (): void => {
			document.title = t("airportalTitle");
		};
	}, []);

	if (!signals.sendSuccessInfo.value?.code) {
		return <></>;
	}
	return (
		<div className="universal-container">
			<h1 className="popup-title">{t("fileSentSuccessfully")}</h1>
			<div className="secondary-text">{t("yourCode")}</div>
			<div className="code selectable">
				{signals.sendSuccessInfo.value.code}
			</div>
			<div className="secondary-text">
				{t("enterAboveCodeWhenReceiving")}
			</div>
			<ActionButtonBar buttons={actionButtons} />
		</div>
	);
}

export default SendSuccess;
