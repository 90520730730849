import WifiTransferButtons from "@/components/wifi-transfer-buttons";
import styles from "@/components/wifi-transfer-mode.module.css";
import * as desktopApp from "@/desktop-app";
import * as signals from "@/signals";
import { MenuItemInfo, WifiTransferPage } from "@/types";
import {
	faArrowRight,
	faPlus,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX } from "react";

interface WifiTransferModeProps {
	setIsClipboardSyncEnabled: (newValue: boolean) => void;
}

function WifiTransferMode({
	setIsClipboardSyncEnabled,
}: WifiTransferModeProps): JSX.Element {
	const buttons: readonly MenuItemInfo[] = [
		{
			hasPopup: false,
			icon: faPlus,
			label: t("create"),
			onClick: (): void => {
				desktopApp.startWifiTransferServer({
					setIsClipboardSyncEnabled,
				});
				signals.wifiTransferPage.value = WifiTransferPage.DASHBOARD;
			},
			when: true,
		},
		{
			hasPopup: false,
			icon: faArrowRight,
			label: t("join"),
			onClick: (): void => {
				signals.wifiTransferPage.value = WifiTransferPage.DEVICE_LIST;
			},
			when: true,
		},
	];

	return (
		<>
			<h1 className="popup-title">{t("wifiTransfer")}</h1>
			<WifiTransferButtons buttons={buttons} />
			<div className={styles["warning"]}>
				<FontAwesomeIcon icon={faTriangleExclamation} />
				{t("wifiTransferWarning")}
			</div>
		</>
	);
}

export default WifiTransferMode;
