import * as api from "@/api";
import LocalWallpaper from "@/classes/local-wallpaper";
import Receiver from "@/classes/receiver";
import AboutPopup from "@/components/about-popup";
import Analytics from "@/components/analytics";
import ArticlePopup from "@/components/article-popup";
import CaptchaIframe from "@/components/captcha-iframe";
import CornerButtons from "@/components/corner-buttons";
import Footer from "@/components/footer";
import LoadingScreen from "@/components/loading-screen";
import LoginPopup from "@/components/login-popup";
import MainBox from "@/components/main-box";
import MainMenu from "@/components/main-menu";
import MessageDialog from "@/components/message-dialog";
import NotificationFloat from "@/components/notification-float";
import Posters from "@/components/posters";
import ReceivePopup from "@/components/receive-popup";
import SendPopup from "@/components/send-popup";
import SenderCodePopup from "@/components/sender-code-popup";
import SettingsPopup from "@/components/settings-popup";
import TitleBar from "@/components/title-bar";
import Toast from "@/components/toast";
import TopWarning from "@/components/top-warning";
import Wallpaper from "@/components/wallpaper";
import WifiTransferPopup from "@/components/wifi-transfer-popup";
import { importSemver, loadDependencies } from "@/dependencies";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { LoginInfo, RealIdLevel, ReceivePage, SendPage } from "@/types";
import {
	addFiles,
	clearPathname,
	closePopup,
	encodeData,
	isMainland,
	loadJs,
	mergeSettings,
	openAccount,
	openPopup,
	replacePathname,
	showDialog,
	showResponseDialog,
	uninstallServiceWorker,
} from "@/utils";
import i18next, { t } from "i18next";
import md5 from "md5";
import { JSX, useCallback, useEffect } from "react";
import { registerSW } from "virtual:pwa-register";

function App(): JSX.Element {
	const areAnyPopupsShown = Object.values(signals.arePopupsShown.value).some(
		(value) => {
			return value;
		},
	);

	const receiveFile = useCallback((newCode: string): void => {
		if (Receiver.isCodeInvalid(newCode)) {
			return;
		}
		signals.code.value = newCode;
		signals.receivePage.value = ReceivePage.CODE;
		openPopup("receive");
		void new Receiver(signals.premiumData.value > 0).receiveFile(newCode);
	}, []);

	const getRecentFile = useCallback(async (): Promise<void> => {
		try {
			if (
				!signals.login.value.username ||
				signals.notification.value?.text
			) {
				return;
			}
			const data = await api.getRecentFile();
			if (!data) {
				return;
			}
			if (data.text) {
				const tipId = md5(data.text);
				if (globals.closedTips.has(tipId)) {
					return;
				}
				globals.notificationCallbacks.onClick = (): void => {
					signals.currentFile.value = {
						code: data.code?.toString(),
						text: data.text,
					};
					signals.receivePage.value = ReceivePage.TEXT_RECEIVED;
					openPopup("receive");
				};
				globals.notificationCallbacks.onClose = (): void => {
					globals.closedTips.add(tipId);
				};
				signals.notification.value = {
					text: decodeURIComponent(data.text),
					title: t("confirmReceiveTextNow"),
				};
			} else if (data.code) {
				const code = data.code.toString();
				if (globals.closedTips.has(code)) {
					return;
				}
				globals.notificationCallbacks.onClick = (): void => {
					receiveFile(code);
				};
				globals.notificationCallbacks.onClose = (): void => {
					globals.closedTips.add(code);
				};
				signals.notification.value = {
					text: decodeURIComponent(data.name || code),
					title: t("confirmReceiveFileNow"),
				};
			}
		} catch (error) {
			api.handleApiError(error);
		}
	}, [receiveFile]);

	const loggedIn = useCallback(
		async (isNewLogin: boolean): Promise<void> => {
			if (isNewLogin) {
				closePopup("login");
				if (desktopApp.isElectron) {
					desktopApp.currentWindow.restore();
				}
				document.getElementById("auth-opt-login-btn")?.click();
			}
			if (
				globals.isApp &&
				signals.login.value.token &&
				signals.login.value.username
			) {
				localStorage.setItem("Token", signals.login.value.token);
				localStorage.setItem("Username", signals.login.value.username);
			}
			try {
				const data = await api.getExpirationTime();
				if (data.data) {
					signals.premiumData.value = data.data;
				}
			} catch (error) {
				api.handleApiError(error);
			}
			try {
				const data = await api.getSettings();
				mergeSettings(data);
			} catch (error) {
				api.handleApiError(error);
			}
			if (!globals.params.code) {
				if (!globals.isApp && isMainland()) {
					try {
						const data = await api.getProfile(["realId"]);
						if (
							data.realIdLevel &&
							data.realIdLevel < RealIdLevel.PHONE_VERIFIED
						) {
							await showDialog(t("requirePhoneNumber"), {
								showCancel: true,
								title: t("realIdVerification"),
							});
							openAccount("", {
								action: "changePhone",
							});
						}
					} catch (error) {
						api.handleApiError(error);
					}
				}
				void getRecentFile();
			}
		},
		[getRecentFile],
	);

	const getInit = useCallback(async () => {
		signals.isLoadingShown.value = true;
		try {
			const data = await api.getInit();
			if (data.blockReason) {
				window.location.href =
					"https://account.retiehe.com/blocked?" +
					encodeData({
						reason: data.blockReason,
					});
				return;
			}
			if (data.redirectTo) {
				window.location.href = data.redirectTo;
				return;
			}
			globals.dynamicInfo = data;
			globals.dynamicInfo.loaded = true;
			if (data.alert) {
				void showResponseDialog(data.alert, data.link);
			}
			if (data.gray) {
				document.documentElement.classList.add("gray");
			}
			if (data.latestVersion && globals.isApp) {
				importSemver()
					.then((semver) => {
						if (
							data.latestVersion &&
							semver.gt(data.latestVersion, globals.VERSION)
						) {
							showDialog(t("confirmUpdate"), {
								showCancel: true,
							})
								.then(() => {
									window.open(
										data.appDlLink || globals.FRONTEND,
									);
								})
								.catch(console.error);
						}
					})
					.catch(console.error);
			}
			if (data.links) {
				signals.links.value = data.links;
			}
			if (data.login) {
				try {
					const newLogin = JSON.parse(data.login) as LoginInfo;
					signals.login.value = {
						...signals.login.value,
						...newLogin,
					};
				} catch (error) {
					console.error(error);
				}
			} else if (!globals.isApp) {
				// The loading animation does not need to wait for this
				// response, so no await here.
				api.getCentralLoginInfo()
					.then((loginInfo) => {
						if (!loginInfo) {
							return;
						}
						window.postMessage(loginInfo, window.location.origin);
					})
					.catch((error) => {
						console.error(error);
					});
			}
			if (signals.login.value.username) {
				void loggedIn(false);
			} else {
				localStorage.removeItem("Token");
				localStorage.removeItem("Username");
			}
			if (data.posters && data.posters.length > 0) {
				signals.posters.value = data.posters;
			}
			if (data.selectedServer) {
				signals.selectedServer.value = data.selectedServer;
			}
			if (data.servers) {
				signals.servers.value = data.servers;
			}
			if (
				data.sw !== undefined &&
				!globals.isApp &&
				process.env.NODE_ENV === "production" &&
				window.location.hostname.endsWith("airportal.cn")
			) {
				// Safari becomes white screen when updating PWA
				if (data.sw && !globals.isIos && !globals.isSafari) {
					registerSW();
				} else {
					void uninstallServiceWorker();
				}
			}
			if (isMainland() || desktopApp.isElectron) {
				void loadJs("https://turing.captcha.qcloud.com/TCaptcha.js");
			}
		} catch (error) {
			const lastUpdated = process.env.LAST_UPDATED;
			if (lastUpdated) {
				const expiredDate = new Date(lastUpdated);
				expiredDate.setFullYear(expiredDate.getFullYear() + 1);
				const expiredTimestamp = expiredDate.getTime();
				if (Date.now() > expiredTimestamp) {
					if (globals.isApp) {
						await showDialog(
							t("endOfLife", {
								date: expiredDate.toLocaleDateString(
									i18next.language,
								),
							}),
						);
						window.open(globals.FRONTEND);
					} else {
						try {
							await uninstallServiceWorker();
						} catch (error) {
							console.error(error);
						}
						await showDialog(t("essentialComponentNotLoaded"));
						window.location.reload();
					}
					return;
				}
			}
			try {
				await api.checkConnectivity();
				void showDialog(t("ispBlockedAirportal"));
			} catch (innerError) {
				console.error(innerError);
				api.handleApiError(error);
			}
		} finally {
			signals.isLoadingShown.value = false;
		}
	}, [loggedIn]);

	useEffect(() => {
		const handleContextMenu = (event: MouseEvent): void => {
			const target = event.target as HTMLElement;
			if (!target) {
				return;
			}
			if (target === document.body) {
				event.preventDefault();
				signals.isMenuShown.value = true;
			}
		};
		window.addEventListener("contextmenu", handleContextMenu);
		return (): void => {
			window.removeEventListener("contextmenu", handleContextMenu);
		};
	}, []);

	useEffect(() => {
		const handleDragOver = (event: DragEvent): void => {
			event.preventDefault();
		};
		window.addEventListener("dragover", handleDragOver);
		return (): void => {
			window.removeEventListener("dragover", handleDragOver);
		};
	}, []);

	useEffect(() => {
		const handleDrop = (event: DragEvent): void => {
			event.preventDefault();
			if (!event.dataTransfer) {
				return;
			}
			if (signals.arePopupsShown.value.wifiTransfer) {
				const filePaths = [...event.dataTransfer.files].map((file) => {
					const path = desktopApp.getPathForFile(file);
					return path;
				});
				if (filePaths.length === 0) {
					return;
				}
				desktopApp.sendFilesLocally(
					signals.wifiTransferServerIp.value
						? [signals.wifiTransferServerIp.value]
						: undefined,
					filePaths,
				);
			}
			if (areAnyPopupsShown) {
				return;
			}
			const files: File[] = [];
			if (
				typeof event.dataTransfer.items[0].webkitGetAsEntry ===
				"function"
			) {
				const readEntry = (
					entry: FileSystemEntry | null,
					isLast: boolean,
				): void => {
					if (!entry) {
						return;
					}
					if (entry.isDirectory) {
						const dirEntry = entry as FileSystemDirectoryEntry;
						dirEntry.createReader().readEntries((entries) => {
							for (let i = 0; i < entries.length; i++) {
								readEntry(
									entries[i],
									isLast && i === entries.length - 1,
								);
							}
						});
					} else {
						const fileEntry = entry as FileSystemFileEntry;
						fileEntry.file((file) => {
							if (file.size > 0 && !file.name.startsWith(".")) {
								files.push(file);
							}
							if (isLast) {
								addFiles(files);
							}
						});
					}
				};
				for (let i = 0; i < event.dataTransfer.items.length; i++) {
					readEntry(
						event.dataTransfer.items[i].webkitGetAsEntry(),
						i === event.dataTransfer.items.length - 1,
					);
				}
			} else {
				for (let i = 0; i < event.dataTransfer.files.length; i++) {
					const file = event.dataTransfer.files[i];
					if (file.size > 0 && file.type) {
						files.push(file);
					}
				}
				addFiles(files);
			}
		};
		window.addEventListener("drop", handleDrop);
		return (): void => {
			window.removeEventListener("drop", handleDrop);
		};
	}, [areAnyPopupsShown]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.ctrlKey || event.metaKey) {
				switch (event.code) {
					case "KeyI":
					case "KeyU": {
						if (process.env.NODE_ENV === "production") {
							event.preventDefault();
						}
						break;
					}
					default:
						break;
				}
			} else if (
				event.key === "F12" &&
				process.env.NODE_ENV === "production"
			) {
				event.preventDefault();
			} else if (
				!areAnyPopupsShown &&
				!isNaN(parseInt(event.key)) // uses .key to handle numpad
			) {
				signals.receivePage.value = ReceivePage.CODE;
				openPopup("receive");
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return (): void => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [areAnyPopupsShown]);

	useEffect(() => {
		const handleMessage = (event: MessageEvent): void => {
			try {
				if (typeof event.data !== "string") {
					return;
				}
				const data = JSON.parse(
					decodeURIComponent(window.atob(event.data)),
				) as {
					action?: string;
					birthday?: string;
					email?: string;
					name?: string;
					phone?: string;
					text?: string;
					token?: string;
					username?: string;
					validate?: string;
				};
				switch (data.action) {
					case "alert": {
						if (data.text) {
							void showDialog(data.text);
						}
						break;
					}
					case "captcha": {
						signals.isCaptchaShown.value = false;
						if (globals.captchaCallback) {
							globals.captchaCallback({
								ticket: data.validate,
							});
							globals.captchaCallback = null;
						}
						break;
					}
					case "login": {
						delete data.action;
						signals.login.value = {
							...signals.login.value,
							...data,
						};
						api.saveLoginInfo();
						void loggedIn(true);
						break;
					}
					default:
						break;
				}
			} catch (error) {
				if (process.env.NODE_ENV === "development") {
					console.warn(event.data, error);
				}
			}
		};
		window.addEventListener("message", handleMessage);
		return (): void => {
			window.removeEventListener("message", handleMessage);
		};
	}, [loggedIn]);

	useEffect(() => {
		const handlePaste = (event: ClipboardEvent): void => {
			if (
				event.target !== document.body ||
				!event.clipboardData ||
				!signals.isMainBoxShown
			) {
				return;
			}
			const items = event.clipboardData.items;
			if (!items || items.length === 0) {
				return;
			}
			const files: File[] = [];
			for (let i = 0; i < items.length; i++) {
				if (items[i].kind === "file") {
					const file = items[i].getAsFile();
					if (file) {
						files.push(file);
					}
				}
			}
			if (files.length > 0) {
				addFiles(files);
			} else if (items[0].kind === "string") {
				items[0].getAsString((text) => {
					if (!text) {
						return;
					}
					signals.currentFile.value = {
						text: text,
					};
					signals.sendPage.value = SendPage.SEND_TEXT;
					openPopup("send");
				});
			}
		};
		document.body.addEventListener("paste", handlePaste);
		return (): void => {
			document.body.removeEventListener("paste", handlePaste);
		};
	}, []);

	useEffect(() => {
		const savedWallpaper = localStorage.getItem("Wallpaper");
		if (!savedWallpaper) {
			return;
		}
		if (savedWallpaper === "local") {
			const localWallpaper = LocalWallpaper.getInstance();
			void localWallpaper.loadWallpaper();
		} else {
			document.documentElement.classList.add("has-wallpaper");
			signals.wallpaperUrl.value = savedWallpaper;
		}
	}, []);

	useEffect(() => {
		const handleLocation = (): boolean => {
			if (
				!globals.isApp &&
				process.env.NODE_ENV === "production" &&
				!window.location.hostname.endsWith("airportal.cn")
			) {
				window.location.href = "https://www.airportal.cn/";
				return false;
			}
			return true;
		};

		if (globals.dynamicInfo.loaded || !handleLocation()) {
			return;
		}
		void getInit();

		if (!globals.isApp && window.location.pathname.length > 1) {
			if (window.location.pathname.endsWith("/")) {
				clearPathname();
			} else {
				const pathParts = window.location.pathname.split("/");
				if (pathParts.length <= 3) {
					const code = pathParts[1];
					globals.params.code = code;
					globals.params.key = decodeURIComponent(pathParts[2]).split(
						" ",
					)[0];
					receiveFile(code);

					// Prevent re-downloading when refreshing
					replacePathname(window.location.pathname + "/");
				}
			}
		}
	}, [getInit, receiveFile]);

	useEffect(loadDependencies, []);

	return (
		<>
			<Wallpaper />
			{desktopApp.isElectron && <TitleBar />}
			{signals.isMainBoxShown.value && (
				<MainBox getRecentFile={getRecentFile} />
			)}
			<TopWarning />
			<CornerButtons />
			<Posters />
			<Footer />
			<MainMenu isOpen={signals.isMenuShown.value} />
			<AboutPopup isOpen={signals.arePopupsShown.value.about} />
			<ArticlePopup isOpen={signals.arePopupsShown.value.article} />
			<ReceivePopup isOpen={signals.arePopupsShown.value.receive} />
			<SendPopup isOpen={signals.arePopupsShown.value.send} />
			<SenderCodePopup
				getRecentFile={getRecentFile}
				isOpen={signals.arePopupsShown.value.senderCode}
			/>
			<SettingsPopup isOpen={signals.arePopupsShown.value.settings} />
			<WifiTransferPopup
				isOpen={signals.arePopupsShown.value.wifiTransfer}
			/>
			<LoginPopup isOpen={signals.arePopupsShown.value.login} />
			{signals.isLoadingShown.value && <LoadingScreen />}
			{signals.isCaptchaShown.value && <CaptchaIframe />}
			<Toast />
			<NotificationFloat />
			<MessageDialog />
			<Analytics />
		</>
	);
}

export default App;
