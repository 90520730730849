import styles from "@/components/receive-code-input.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, JSX, RefObject, useId } from "react";

interface ReceiveCodeInputProps {
	inputRef: RefObject<HTMLInputElement | null>;
	receiveFile: (code: string) => void;
}

function ReceiveCodeInput({
	inputRef,
	receiveFile,
}: ReceiveCodeInputProps): JSX.Element {
	const id = useId();

	const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		signals.code.value = event.target.value;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		if (signals.code) {
			if (signals.invalidCodeAttempts.value > 2) {
				globals.captchaRequiredFor.add("receive");
			}
			receiveFile(signals.code.value);
		} else {
			inputRef.current?.focus();
		}
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterCode")}</label>
				<div className="tip">{t("youWillGetCodeAfterSendingFile")}</div>
			</div>
			<div>
				<input
					className={clsx(
						"input",
						styles["receive-input"],
						signals.code.value.length > 6 && styles["compact"],
					)}
					data-autofocus
					data-testid="code-input"
					id={id}
					ref={inputRef}
					type="tel"
					autoComplete="off"
					inputMode="numeric"
					value={signals.code.value}
					onChange={handleCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default ReceiveCodeInput;
