import styles from "@/components/login-popup.module.css";
import PopupShell from "@/components/popup-shell";
import { globals } from "@/globals";
import { closePopup, encodeData } from "@/utils";
import { t } from "i18next";
import { JSX, useState } from "react";

interface LoginPopupProps {
	isOpen: boolean;
}

function LoginPopup({ isOpen }: LoginPopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeLoginPopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("login");
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	return (
		<PopupShell
			isClosing={isClosing}
			isMaximized={true}
			isOpen={isOpen}
			onRequestClose={closeLoginPopup}
		>
			<div className={styles["iframe-wrapper"]}>
				<iframe
					src={
						"https://account.retiehe.com/login?" +
						encodeData({
							appname: globals.APP_NAME,
							page: "login",
							region: globals.dynamicInfo.region,
							test:
								process.env.NODE_ENV === "development"
									? "1"
									: undefined,
							theme: globals.isDark ? "dark" : "light",
						})
					}
					title={t("login")}
				></iframe>
			</div>
		</PopupShell>
	);
}

export default LoginPopup;
