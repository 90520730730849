import styles from "@/components/posters.module.css";
import * as signals from "@/signals";
import { handleKeyboardClick, openArticle } from "@/utils";
import clsx from "clsx";
import { JSX } from "react";

function Posters(): JSX.Element {
	if (!signals.posters.value || signals.posters.value.length === 0) {
		return <></>;
	}

	const posterElements = signals.posters.value.map((item) => {
		const handleClick = (): void => {
			if (!signals.posters.value) {
				return;
			}
			if (item.isArticle) {
				openArticle(item);
			} else {
				window.open(item.link);
			}
			// Remove the clicked poster from the list
			const newPosters = signals.posters.value.filter((newPoster) => {
				return newPoster.src !== item.src;
			});
			signals.posters.value = newPosters;
		};

		return (
			<div
				aria-haspopup={item.isArticle ? "dialog" : undefined}
				key={item.src}
				className={clsx(styles["poster"], "fade-in")}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				<img
					src={item.src}
					alt={item.alt}
				/>
			</div>
		);
	});

	return <div className={styles["posters"]}>{posterElements}</div>;
}

export default Posters;
