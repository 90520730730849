import { globals } from "@/globals";
import * as signals from "@/signals";
import { showDialog } from "@/utils";
import { t } from "i18next";

class LocalWallpaper {
	private static readonly DATABASE_NAME = "wallpaper";
	private static readonly OBJECT_KEY = "custom";

	public static instance: LocalWallpaper | null = null;
	private database: IDBDatabase | null;

	public constructor() {
		LocalWallpaper.instance = this;
		this.database = null;
	}

	private async connectDatabase(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (this.database) {
				resolve();
				return;
			}
			const dbRequest = indexedDB.open(globals.APP_NAME.toLowerCase(), 1);
			dbRequest.onsuccess = (): void => {
				this.database = dbRequest.result;
				resolve();
			};
			dbRequest.onerror = reject;
			dbRequest.onupgradeneeded = (): void => {
				this.database = null;
				const store = dbRequest.result.createObjectStore("wallpaper");
				store.transaction.oncomplete = (): void => {
					this.database = store.transaction.db;
					resolve();
				};
			};
		});
	}

	public async deleteWallpaper(): Promise<void> {
		await this.connectDatabase();
		if (!this.database) {
			return;
		}
		this.database
			.transaction(LocalWallpaper.DATABASE_NAME, "readwrite")
			.objectStore(LocalWallpaper.DATABASE_NAME)
			.clear();
	}

	public static getInstance(): LocalWallpaper {
		if (!LocalWallpaper.instance) {
			return new LocalWallpaper();
		}
		return LocalWallpaper.instance;
	}

	private async getWallpaper(): Promise<File | null> {
		try {
			await this.connectDatabase();
			if (!this.database) {
				return null;
			}
			const objectStore = this.database
				.transaction(LocalWallpaper.DATABASE_NAME)
				.objectStore(LocalWallpaper.DATABASE_NAME);
			const file = await this.promisfyRequest<File>(
				objectStore.get(LocalWallpaper.OBJECT_KEY),
			);
			return file;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	public async loadWallpaper(): Promise<void> {
		const wallpaper = await this.getWallpaper();
		if (!wallpaper) {
			return;
		}
		this.setWallpaper(wallpaper);
	}

	private promisfyRequest<T>(request: IDBRequest): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			request.onerror = (): void => {
				reject(new Error(request.error?.message));
			};
			request.onsuccess = (): void => {
				resolve(request.result as T);
			};
		});
	}

	public async saveWallpaper(file: File): Promise<void> {
		try {
			await this.connectDatabase();
			if (!this.database) {
				return;
			}
			this.database
				.transaction(LocalWallpaper.DATABASE_NAME, "readwrite")
				.objectStore(LocalWallpaper.DATABASE_NAME)
				.put(file, LocalWallpaper.OBJECT_KEY);
			localStorage.setItem("Wallpaper", "local");
			this.setWallpaper(file);
		} catch (error) {
			console.error(error);
			void showDialog(t("unableSetWallpaperInIncognito"));
		}
	}

	public setWallpaper(file: File): void {
		if (!file) {
			return;
		}
		const newWallpaperUrl = URL.createObjectURL(file);
		document.documentElement.classList.add("has-wallpaper");
		signals.wallpaperUrl.value = newWallpaperUrl;
	}
}

export default LocalWallpaper;
