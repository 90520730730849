import * as api from "@/api";
import Reader from "@/classes/reader";
import styles from "@/components/send-file-list.module.css";
import { importAliOss } from "@/dependencies";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { OssCredentials, SendPage } from "@/types";
import {
	closePopup,
	getFileIcon,
	isMainland,
	logIn,
	openAccount,
	showCaptcha,
	showDialog,
	showResponseDialog,
	sleep,
	updateProgressPercent,
} from "@/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkpoint } from "ali-oss";
import { t } from "i18next";
import {
	ChangeEvent,
	Dispatch,
	FormEvent,
	JSX,
	SetStateAction,
	useId,
	useState,
} from "react";

const PART_SIZE = 5_242_880; // 5 MB
const RETRY_COUNT_LIMIT = 5;
const STS_TOKEN_TIMEOUT = 600_000; // 10 minutes

function SendFileList(): JSX.Element {
	const setDownloadsId = useId();
	const setHoursId = useId();
	const setPasswordId = useId();

	const defaultDownloads = Math.min(
		10,
		signals.pendingUploadFiles.value.length + 1,
	);

	const [downloads, setDownloads] = useState<string>(
		defaultDownloads.toString(),
	);
	const [hours, setHours] = useState<string>("24");
	const [password, setPassword] = useState<string>("");

	const sender =
		signals.login.value.email ||
		signals.login.value.phone ||
		(signals.senderCode.value
			? `${t("senderCode")} ${signals.senderCode.value}`
			: t("notLoggedIn"));

	const fileNameElements = signals.pendingUploadFiles.value.map(
		(file, index) => {
			return (
				<li
					className={styles["list-item"]}
					key={index}
				>
					<FontAwesomeIcon
						className="file-icon"
						fixedWidth
						icon={getFileIcon(file.type)}
					/>
					{file.name}
				</li>
			);
		},
	);

	const handleChange = (setter: Dispatch<SetStateAction<string>>) => {
		return (event: ChangeEvent<HTMLInputElement>): void => {
			setter(event.target.value);
		};
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		void upload();
	};

	const upload = async (): Promise<void> => {
		let downloadsNumber = parseInt(downloads);
		if (!downloadsNumber || downloadsNumber < 1) {
			setDownloads(defaultDownloads.toString());
			downloadsNumber = defaultDownloads;
		}
		const action =
			"send" + (signals.senderCode.value ? "WithSenderCode" : "");
		if (
			!signals.login.value.username &&
			isMainland() &&
			!signals.senderCode
		) {
			await showDialog(t("requireLoginToSendFiles"));
			logIn();
			return;
		}
		signals.isLoadingShown.value = true;
		let content: string | undefined = "";
		let hash = "";
		let type = "";
		try {
			const reader = new Reader(globals.pendingUploadFiles);
			const result = await reader.readFile();
			content = result.content;
			hash = result.hash;
			type = result.type;
		} catch (error) {
			if (process.env.NODE_ENV === "development") {
				console.warn(error);
			}
		}
		const captchaResponse = await showCaptcha(action);
		try {
			const data = await api.sendFiles({
				captchaResponse: captchaResponse,
				content: content,
				downloads: downloadsNumber,
				files: signals.pendingUploadFiles.value,
				hash: hash,
				hours: parseInt(hours),
				password: password,
				senderCode: signals.senderCode.value,
				type: type,
			});
			signals.isLoadingShown.value = false;
			if (data.error) {
				switch (data.error) {
					case "captchaRequired": {
						if (!globals.captchaRequiredFor.has(action)) {
							globals.captchaRequiredFor.add(action);
							await upload();
							return;
						}
						break;
					}
					case "invalidSenderCode": {
						signals.invalidSenderCodeAttempts.value++;
						data.alert = t("senderCodeInvalidOrExpired");
						break;
					}
					case "premiumRequired": {
						await showDialog(data.alert || data.error, {
							showCancel: true,
						});
						if (signals.login.value.username) {
							openAccount("premium");
						} else {
							logIn();
						}
						return;
					}
					default:
						break;
				}
				await showResponseDialog(data.alert || data.error, data.link);
				signals.sendPage.value = SendPage.ID_SELECTOR;
				return;
			}
			if (!data.code) {
				throw new Error("Missing code");
			}
			if (!data.id || !data.secret) {
				throw new Error("Missing credentials");
			}
			const bucket = data.server;
			if (!bucket) {
				throw new Error("Missing bucket");
			}
			desktopApp.startSleepBlocker();
			const OSS = await importAliOss();
			const ossClient = new OSS({
				accessKeyId: data.id,
				accessKeySecret: data.secret,
				bucket: data.server,
				cname: true,
				endpoint: "https://" + data.endpoint,
				refreshSTSToken: async (): Promise<OssCredentials> => {
					return await api.renewStsToken(data.code?.toString());
				},
				refreshSTSTokenInterval: 900000,
				region: data.region,
				stsToken: data.token,
			});
			let currentCheckpoint: Checkpoint | undefined = undefined;
			let retryCount = 0;
			for (const file of globals.pendingUploadFiles) {
				if (file.size <= 0) {
					continue;
				}
				const ossKey = [data.code, data.key, 1, file.name].join("/");
				const percent = 0;
				const partCount = Math.ceil(file.size / PART_SIZE);
				signals.progress.value = {
					denominator: partCount,
					filename: file.name,
					isLargeFile:
						signals.premiumData.value > 0 &&
						file.size > globals.PREMIUM_REQUIRED_FILE_SIZE,
					percent: percent,
				};
				signals.sendPage.value = SendPage.PROGRESS;

				const uploadToOss = async (): Promise<void> => {
					try {
						await ossClient.multipartUpload(ossKey, file, {
							checkpoint: currentCheckpoint,
							partSize: PART_SIZE,
							progress: (
								percent: number,
								checkpoint: Checkpoint,
							): void => {
								currentCheckpoint = checkpoint;
								updateProgressPercent(percent);
							},
							timeout: STS_TOKEN_TIMEOUT,
						});
					} catch (error) {
						console.error(error);
						if (retryCount < RETRY_COUNT_LIMIT) {
							// Update the STS token
							await ossClient.asyncSignatureUrl(ossKey);

							retryCount++;
							await sleep(3000);
							await uploadToOss();
							return;
						}
						await showDialog(
							<>
								{t("confirmRetryUpload")}
								{error instanceof Error && (
									<div className={styles["detailed-message"]}>
										{error.message}
									</div>
								)}
							</>,
							{
								showCancel: true,
							},
							{
								cancel: () => {
									closePopup("send");
								},
							},
						);
						retryCount = 0;
						await uploadToOss();
						return;
					}
				};

				await uploadToOss();
			}
			await api.sendSuccess(data.code.toString());
			signals.sendSuccessInfo.value = {
				code: data.code,
				key: data.key,
			};
			signals.sendPage.value = SendPage.SUCCESS;
			globals.filesSentCount++;
			desktopApp.stopSleepBlocker();
		} catch (error) {
			signals.isLoadingShown.value = false;
			api.handleApiError(error);
		}
	};

	return (
		<div className="universal-container">
			<div className="popup-title no-margin">
				{t("youSelectedTheseFiles")}
			</div>
			<p className={styles["login-status"]}>{sender}</p>
			<ul className="file-list">{fileNameElements}</ul>
			<form onSubmit={handleSubmit}>
				<div className={styles["input-bar"]}>
					<label htmlFor={setPasswordId}>{t("password")}</label>
					<input
						autoComplete="off"
						data-testid="set-password-input"
						id={setPasswordId}
						placeholder={t("optional")}
						type="text"
						value={password}
						onChange={handleChange(setPassword)}
					/>
				</div>
				<div className={styles["input-bar"]}>
					<label htmlFor={setDownloadsId}>{t("downloads")}</label>
					<input
						autoComplete="off"
						id={setDownloadsId}
						type="number"
						value={downloads}
						onChange={handleChange(setDownloads)}
					/>
					<label htmlFor={setHoursId}>{t("expiresInHours")}</label>
					<input
						autoComplete="off"
						id={setHoursId}
						type="number"
						value={hours}
						onChange={handleChange(setHours)}
					/>
				</div>
				<button
					autoFocus
					className="popup-main-button"
					type="submit"
				>
					{t("upload")}
				</button>
			</form>
		</div>
	);
}

export default SendFileList;
