import styles from "@/components/toast.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { JSX, useCallback, useState } from "react";

function Toast(): JSX.Element {
	const [isClosing, setIsClosing] = useState(false);

	const closeToast = useCallback((): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			signals.toast.value = null;
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	}, []);

	useSignalEffect(() => {
		let timeoutId: number | undefined = undefined;
		if (signals.toast.value?.text) {
			timeoutId = window.setTimeout(() => {
				closeToast();
			}, signals.toast.value.timeout || 3000);
		}
		return (): void => {
			window.clearTimeout(timeoutId);
		};
	});

	if (!signals.toast.value?.text) {
		return <></>;
	}
	return (
		<div className={clsx(styles["toast"], isClosing && styles["closing"])}>
			{signals.toast.value.text}
		</div>
	);
}

export default Toast;
