import styles from "@/components/corner-buttons.module.css";
import Greeting from "@/components/greeting";
import * as signals from "@/signals";
import { ReceivePage } from "@/types";
import { logIn, openPopup } from "@/utils";
import { faBars, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

function CornerButtons(): JSX.Element {
	const openMenu = (): void => {
		signals.isMenuShown.value = true;
	};

	const openMyFiles = (): void => {
		if (!signals.login.value.username) {
			logIn();
			return;
		}
		signals.receivePage.value = ReceivePage.MY_FILES;
		openPopup("receive");
	};

	return (
		<div className={styles["corner-buttons-container"]}>
			<Greeting />
			<button
				aria-haspopup={
					signals.login.value.username ? "dialog" : undefined
				}
				className={clsx(
					styles["corner-button"],
					signals.isMyFilesButtonHighlighted.value &&
						styles["highlight"],
				)}
				title={t("myFiles")}
				type="button"
				onClick={openMyFiles}
			>
				<FontAwesomeIcon icon={faFolder} />
				{t("myFiles")}
			</button>
			<button
				aria-haspopup="menu"
				className={clsx(styles["corner-button"], styles["no-text"])}
				title={t("menu")}
				type="button"
				onClick={openMenu}
			>
				<FontAwesomeIcon icon={faBars} />
			</button>
		</div>
	);
}

export default CornerButtons;
