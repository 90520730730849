import PopupShell from "@/components/popup-shell";
import Progress from "@/components/progress";
import QrCode from "@/components/qr-code";
import SendAgreement from "@/components/send-agreement";
import SendFileList from "@/components/send-file-list";
import SendIdProvider from "@/components/send-id-provider";
import styles from "@/components/send-popup.module.css";
import SendSuccess from "@/components/send-success";
import SenderCodeInput from "@/components/sender-code-input";
import Slides from "@/components/slides";
import TextArea from "@/components/text-area";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { SendPage, TextAreaPage } from "@/types";
import { closePopup, sleep } from "@/utils";
import { t } from "i18next";
import { JSX, useCallback, useEffect, useRef, useState } from "react";

interface SendPopupProps {
	isOpen: boolean;
}

function SendPopup({ isOpen }: SendPopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [showCodeFloat, setShowCodeFloat] = useState<boolean>(false);

	const codeFloatRef = useRef<HTMLDivElement>(null);

	const closeSendPopup = useCallback((): void => {
		if (signals.sendPage.value === SendPage.QR_CODE) {
			signals.sendPage.value = SendPage.SUCCESS;
			return;
		}
		if (signals.sendPage.value === SendPage.SENDER_CODE) {
			signals.sendPage.value = SendPage.ID_SELECTOR;
			return;
		}
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("send");
			signals.currentFile.value = null;
			signals.pendingUploadFiles.value = [];
			signals.progress.value = null;
			signals.senderCode.value = "";
			setIsClosing(false);
			globals.pendingUploadFiles = [];
			if (signals.sendPage.value === SendPage.SUCCESS) {
				setShowCodeFloat(true);
			}
		}, globals.ANIMATION_WAIT_TIME);
	}, []);

	const runCodeAnimation = useCallback(async (): Promise<void> => {
		if (!codeFloatRef.current) {
			return;
		}
		const floatStyle = codeFloatRef.current.style;
		const floatWidth = codeFloatRef.current.offsetWidth;
		floatStyle.right = `calc(50% - ${floatWidth / 2}px)`;
		await sleep(500);
		floatStyle.transform = "scale(.5)";
		await sleep(250);
		floatStyle.right = `${81.58 - (floatWidth * 0.5) / 2}px`;
		floatStyle.top = "2px";
		await sleep(1000);
		floatStyle.transform = "scale(0)";
		signals.isMyFilesButtonHighlighted.value = true;
		await sleep(500);
		floatStyle.opacity = "0";
		await sleep(250);
		setShowCodeFloat(false);
		await sleep(250);
		signals.isMyFilesButtonHighlighted.value = false;
	}, []);

	useEffect(() => {
		if (!showCodeFloat) {
			return;
		}
		void runCodeAnimation();
	}, [runCodeAnimation, showCodeFloat]);

	return (
		<>
			<PopupShell
				isClosing={isClosing}
				isOpen={isOpen}
				onRequestClose={
					signals.sendPage.value === SendPage.PROGRESS
						? undefined
						: closeSendPopup
				}
			>
				<Slides currentPage={signals.sendPage.value}>
					<TextArea currentPage={TextAreaPage.SEND_TEXT} />
					<SendAgreement closeSendPopup={closeSendPopup} />
					<SendFileList />
					<SendIdProvider />
					<SenderCodeInput />
					<Progress title={t("uploading")} />
					<SendSuccess />
					<QrCode />
				</Slides>
			</PopupShell>
			{showCodeFloat && (
				<div
					className={styles["code-float"]}
					ref={codeFloatRef}
				>
					{signals.sendSuccessInfo.value?.code}
				</div>
			)}
		</>
	);
}

export default SendPopup;
