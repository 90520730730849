import styles from "@/components/progress.module.css";
import * as signals from "@/signals";
import { getHelp, getRandomInteger, roundToDecimal } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useEffect, useRef, useState } from "react";

interface ProgressProps {
	title: string;
}

function Progress({ title }: ProgressProps): JSX.Element {
	const speedBreakpoints = useRef([
		getRandomInteger(11, 30),
		getRandomInteger(31, 50),
		getRandomInteger(51, 65),
		getRandomInteger(66, 80),
	]);

	const providedPercent = signals.progress.value?.percent || 0;

	const [percentOverride, setPercentOverride] =
		useState<number>(providedPercent);

	const percent = roundToDecimal(
		Math.max(percentOverride, providedPercent) * 100,
		signals.progress.value?.isLargeFile ? 2 : 0,
	);

	useEffect(() => {
		if (
			!signals.progress.value?.denominator || // needed for predicting progress
			signals.progress.value?.isLargeFile ||
			signals.progress.value.percent === undefined
		) {
			return;
		}
		let delay: number;
		const percentHundreds = Math.floor(percentOverride * 100);
		if (percentHundreds > speedBreakpoints.current[3]) {
			delay = getRandomInteger(60_000, 3_600_000);
		} else if (percentHundreds > speedBreakpoints.current[2]) {
			delay = 60_000;
		} else if (percentHundreds > speedBreakpoints.current[1]) {
			delay = getRandomInteger(10_000, 60_000);
		} else if (percentHundreds > speedBreakpoints.current[0]) {
			delay = getRandomInteger(2_000, 10_000);
		} else {
			delay = 750;
		}
		const intervalId = window.setTimeout(() => {
			if (
				!signals.progress.value?.denominator ||
				signals.progress.value.percent === undefined
			) {
				return;
			}
			if (signals.progress.value.percent > percentOverride) {
				setPercentOverride(signals.progress.value.percent);
				return;
			}
			const nextPercent =
				signals.progress.value.percent +
				1 / signals.progress.value.denominator;
			const increasedPercent = percentOverride + 0.01;
			if (
				increasedPercent > 0.8 &&
				(increasedPercent >= nextPercent || increasedPercent >= 0.99)
			) {
				return;
			}
			setPercentOverride(increasedPercent);
		}, delay);
		return (): void => {
			window.clearInterval(intervalId);
		};
	}, [percentOverride]);

	useEffect(() => {
		if (percent === 0) {
			return;
		}
		document.title = `[${percent}%] ${t("airportalTitle")}`;
		return (): void => {
			document.title = t("airportalTitle");
		};
	}, [percent]);

	if (signals.progress.value?.percent === undefined) {
		return <></>;
	}
	return (
		<>
			<div className={clsx("universal-container", styles["container"])}>
				<div className={clsx("popup-title", styles["popup-title"])}>
					{percent >= 99 ? t("almostThere") : title}
				</div>
				<div className={styles["description"]}>
					{signals.progress.value.filename}
				</div>
				<div className={styles["progress-label"]}>
					{percent}
					<span>%</span>
				</div>
				<div
					className={clsx(
						styles["progress-bar"],
						styles["progress-bar-background"],
					)}
				>
					<div
						className={clsx(
							styles["progress-bar"],
							styles["progress-bar-foreground"],
						)}
						role="progressbar"
						style={{ width: percent + "%" }}
						aria-valuenow={percent}
						aria-valuemin={0}
						aria-valuemax={100}
					>
						<div className={styles["progress-bar-highlight"]}></div>
					</div>
				</div>
			</div>
			{signals.progress.value.isLargeFile && (
				<button
					className={styles["description"]}
					onClick={getHelp}
					type="button"
				>
					{t("ifTransferFailsClickHereToReturnPremiumData")}
				</button>
			)}
		</>
	);
}

export default Progress;
