import styles from "@/components/captcha-iframe.module.css";
import { globals } from "@/globals";
import { encodeData } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useRef } from "react";

function CaptchaIframe(): JSX.Element {
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const handleLoad = (): void => {
		iframeRef.current?.focus();
	};

	return (
		<div className={clsx(styles["captcha"], "fade-in")}>
			<iframe
				ref={iframeRef}
				src={
					"https://www.retiehe.com/captcha?" +
					encodeData({
						region: globals.dynamicInfo.region,
					})
				}
				title={t("captcha")}
				onLoad={handleLoad}
			></iframe>
		</div>
	);
}

export default CaptchaIframe;
