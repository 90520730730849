import styles from "@/components/qr-code.module.css";
import * as signals from "@/signals";
import { QRCodeSVG } from "qrcode.react";
import { JSX } from "react";

function QrCode(): JSX.Element {
	return (
		<div className="universal-container">
			<div className={styles["qr-code"]}>
				<QRCodeSVG
					size={130}
					value={signals.qrCode.value}
				/>
			</div>
		</div>
	);
}

export default QrCode;
