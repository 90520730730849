import styles from "@/components/slides.module.css";
import clsx from "clsx";
import { JSX } from "react";

interface SlidesProps {
	children?: JSX.Element[];
	currentPage: number;
}

function Slides({ children, currentPage }: SlidesProps): JSX.Element {
	if (!children || children.length === 0) {
		return <></>;
	}

	const elements = children.map((item, index) => {
		const isCurrentPage = currentPage === index;
		return (
			<div
				className={clsx(
					styles["slide-container"],
					!isCurrentPage && styles["invisible"],
					currentPage > index && styles["slide-left"],
					currentPage < index && styles["slide-right"],
				)}
				key={index}
			>
				{item}
			</div>
		);
	});

	return <>{elements}</>;
}

export default Slides;
