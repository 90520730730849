import styles from "@/components/message-dialog.module.css";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { Dialog } from "@headlessui/react";
import { useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { t } from "i18next";
import {
	ChangeEvent,
	JSX,
	KeyboardEvent,
	MouseEvent,
	useRef,
	useState,
} from "react";

function MessageDialog(): JSX.Element {
	const [inputValue, setInputValue] = useState<string>(
		signals.dialog.value?.defaultText || "",
	);
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const closeDialog = (): void => {
		globals.dialogCallbacks = {};
		setIsClosing(true);
		window.setTimeout(() => {
			setInputValue("");
			signals.dialog.value = {};
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleCancelClick = (event: MouseEvent<HTMLButtonElement>): void => {
		globals.dialogCallbacks.cancel?.(event);
		closeDialog();
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleInputKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
	): void => {
		if (event.key === "Enter") {
			handleOkClick();
		}
	};

	const handleOkClick = (event?: MouseEvent<HTMLButtonElement>): void => {
		globals.dialogCallbacks.ok?.(event, inputValue);
		closeDialog();
	};

	const extraButtonElements = signals.dialog.value?.extraButtons?.map(
		(item) => {
			const handleClick = (
				event: MouseEvent<HTMLButtonElement>,
			): void => {
				globals.dialogCallbacks[item]?.(event);
				closeDialog();
			};

			return (
				<button
					className={clsx(
						styles["dialog-button"],
						styles["negative"],
					)}
					key={item}
					type="button"
					onClick={handleClick}
				>
					{t(item)}
				</button>
			);
		},
	);

	useSignalEffect(() => {
		if (signals.dialog.value?.isPrompt) {
			window.setTimeout(() => {
				inputRef.current?.focus();
			}, globals.ANIMATION_WAIT_TIME);
		}
	});

	return (
		<Dialog
			onClose={closeDialog}
			open={!!signals.dialog.value?.html || !!signals.dialog.value?.text}
		>
			<div
				className={clsx(
					"overlay",
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<div className={styles["dialog"]}>
					{!signals.dialog.value?.isPrompt && (
						<div className={styles["dialog-title"]}>
							{signals.dialog.value?.title ||
								(signals.dialog.value?.showCancel
									? t("confirm")
									: t("alert"))}
						</div>
					)}
					<div className={styles["dialog-content"]}>
						{signals.dialog.value?.html && (
							<span
								dangerouslySetInnerHTML={{
									__html: signals.dialog.value.html,
								}}
							></span>
						)}
						{signals.dialog.value?.text}
					</div>
					{signals.dialog.value?.isPrompt && (
						<input
							ref={inputRef}
							className={clsx("input", styles["dialog-input"])}
							placeholder={signals.dialog.value.placeholder}
							type={signals.dialog.value.inputType || "text"}
							value={inputValue}
							onChange={handleInputChange}
							onContextMenu={desktopApp.pasteFromClipboard}
							onKeyDown={handleInputKeyDown}
						/>
					)}
					<div className={styles["dialog-buttons"]}>
						{extraButtonElements}
						{signals.dialog.value?.showCancel && (
							<button
								className={clsx(
									styles["dialog-button"],
									styles["negative"],
								)}
								type="button"
								onClick={handleCancelClick}
							>
								{t("cancel")}
							</button>
						)}
						<button
							className={clsx(
								styles["dialog-button"],
								styles["positive"],
							)}
							data-autofocus
							type="button"
							onClick={handleOkClick}
						>
							{signals.dialog.value?.okText || t("ok")}
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default MessageDialog;
