import styles from "@/components/title-bar.module.css";
import * as desktopApp from "@/desktop-app";
import { JSX } from "react";

function TitleBar(): JSX.Element {
	const handleDoubleClick = (): void => {
		const window = desktopApp.electronRemote.getCurrentWindow();
		if (window.isMaximized()) {
			window.unmaximize();
		} else {
			window.maximize();
		}
	};

	return (
		<div
			className={styles["title-bar"]}
			onDoubleClick={handleDoubleClick}
		></div>
	);
}

export default TitleBar;
