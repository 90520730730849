import {
	DialogInfo,
	FileInfo,
	LinkInfo,
	LoginInfo,
	NotificationInfo,
	PosterInfo,
	ProgressInfo,
	ReceivePage,
	SendPage,
	SendSuccessInfo,
	ServerInfo,
	SettingsInfo,
	ToastInfo,
	WifiTransferPage,
} from "@/types";
import { signal } from "@preact/signals";

export const arePopupsShown = signal({
	about: false,
	article: false,
	login: false,
	receive: false,
	send: false,
	senderCode: false,
	settings: false,
	wifiTransfer: false,
});
export const isMenuShown = signal(false);
export const article = signal<PosterInfo | null>(null);
export const code = signal("");
export const currentFile = signal<FileInfo | null>(null);
export const dialog = signal<DialogInfo | null>(null);
export const invalidCodeAttempts = signal(0);
export const invalidSenderCodeAttempts = signal(0);
export const isCaptchaShown = signal(false);
export const isLoadingShown = signal(false);
export const isMainBoxShown = signal(true);
export const isMyFilesButtonHighlighted = signal(false);
export const links = signal<LinkInfo[] | null>(null);
export const login = signal<LoginInfo>({});
export const notification = signal<NotificationInfo | null>(null);
export const password = signal("");
export const pendingUploadFiles = signal<FileInfo[]>([]);
export const posters = signal<PosterInfo[] | null>(null);
export const premiumData = signal(0);
export const progress = signal<ProgressInfo | null>(null);
export const qrCode = signal("");
export const receivedFiles = signal<FileInfo[]>([]);
export const receivePage = signal<ReceivePage>(ReceivePage.CODE);
export const selectedServer = signal("");
export const senderCode = signal("");
export const sendPage = signal<SendPage>(SendPage.FILE_LIST);
export const sendSuccessInfo = signal<SendSuccessInfo | null>(null);
export const servers = signal<ServerInfo>({});
export const settings = signal<SettingsInfo>({});
export const toast = signal<ToastInfo | null>(null);
export const wallpaperUrl = signal<string | null>(null);
export const wifiTransferPage = signal<WifiTransferPage>(WifiTransferPage.MODE);
export const wifiTransferServerIp = signal("");
