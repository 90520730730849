import * as api from "@/api";
import Downloader from "@/classes/downloader";
import styles from "@/components/text-area.module.css";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { ReceivePage, SendPage, TextAreaPage, WifiTransferPage } from "@/types";
import {
	closePopup,
	copyText,
	isMainland,
	logIn,
	showCaptcha,
	showDialog,
	showResponseDialog,
} from "@/utils";
import { computed, useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useEffect, useId, useState } from "react";

interface TextAreaProps {
	currentPage: TextAreaPage;
}

function TextArea({ currentPage }: TextAreaProps): JSX.Element {
	const id = useId();

	const defaultText = computed(() => {
		return {
			[TextAreaPage.SEND_TEXT]: signals.currentFile.value?.text || "",
			[TextAreaPage.SEND_TEXT_LOCALLY]:
				signals.currentFile.value?.text || "",
			[TextAreaPage.TEXT_RECEIVED]: signals.currentFile.value?.text || "",
			[TextAreaPage.TEXT_RECEIVED_LOCALLY]:
				signals.currentFile.value?.text || "",
			[TextAreaPage.REPORT]: t("reportText", {
				code: signals.currentFile.value?.code || "",
			}),
		}[currentPage];
	});
	const region = signals.currentFile.value?.region;

	const [inputValue, setInputValue] = useState<string>(defaultText.value);
	const [isLinkDangerous, setIsLinkDangerous] = useState<boolean>(false);
	const [warning, setWarning] = useState<string>(
		{
			[TextAreaPage.SEND_TEXT]: "",
			[TextAreaPage.SEND_TEXT_LOCALLY]: "",
			[TextAreaPage.TEXT_RECEIVED]: t("dlWarnText", {
				region: region
					? `<span class="box">IP 属地${region}</span>的`
					: "",
				reportToUs: `<a class="report-link" href="#">${t("reportToUs")}</a>`,
			}),
			[TextAreaPage.TEXT_RECEIVED_LOCALLY]: "",
			[TextAreaPage.REPORT]: t("warnInvalidReport"),
		}[currentPage],
	);

	const textNoNewLines = signals.currentFile.value?.text
		? signals.currentFile.value.text.replaceAll("\n", "")
		: "";
	const isLink = textNoNewLines
		? /^[a-z]+:\/\/[a-z0-9_\-/.#?=%]+$/i.test(textNoNewLines)
		: false;

	const title = {
		[TextAreaPage.SEND_TEXT]: t("sendText"),
		[TextAreaPage.SEND_TEXT_LOCALLY]: t("sendText"),
		[TextAreaPage.TEXT_RECEIVED]: t("textReceived"),
		[TextAreaPage.TEXT_RECEIVED_LOCALLY]: t("textReceived"),
		[TextAreaPage.REPORT]: t("report"),
	}[currentPage];

	let buttonText: string;
	if (currentPage === TextAreaPage.TEXT_RECEIVED) {
		if (isLink) {
			buttonText = t("open");
		} else if (globals.isApp || navigator.clipboard) {
			buttonText = t("copy");
		} else {
			buttonText = t("download");
		}
	} else {
		buttonText = t("send");
	}

	const handleTextAreaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	): void => {
		setInputValue(event.target.value);
	};

	const handleButtonClick = (): void => {
		if (currentPage === TextAreaPage.TEXT_RECEIVED) {
			if (isLink) {
				if (isLinkDangerous) {
					void showDialog(warning);
				} else {
					window.open(inputValue);
				}
			} else if (globals.isApp || navigator.clipboard) {
				void copyText(inputValue);
			} else {
				Downloader.downloadText(inputValue, t("textDocument") + ".txt");
			}
			return;
		}
		if (
			!inputValue ||
			(currentPage === TextAreaPage.REPORT &&
				inputValue === defaultText.value)
		) {
			void showDialog(t("enterText"));
			return;
		}
		if (currentPage === TextAreaPage.SEND_TEXT) {
			globals.sendCallback = sendText;
			signals.sendPage.value = SendPage.AGREEMENT;
		} else if (currentPage === TextAreaPage.SEND_TEXT_LOCALLY) {
			if (globals.wifiTransferIp) {
				desktopApp.sendTextToServer(inputValue);
			} else {
				desktopApp.sendTextToClient(inputValue);
			}
			signals.toast.value = {
				text: t("textSent"),
			};
			signals.wifiTransferPage.value = WifiTransferPage.DASHBOARD;
		} else if (currentPage === TextAreaPage.REPORT) {
			void sendReport();
		}
	};

	const sendReport = async (): Promise<void> => {
		signals.isLoadingShown.value = true;
		try {
			await api.sendFeedback(inputValue, t("report"));
			void showDialog(t("feedbackSentSuccessfully"));
			closePopup("receive");
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingShown.value = false;
		}
	};

	const sendText = async (senderCode?: string): Promise<void> => {
		const action = "sendText" + (senderCode ? "WithSenderCode" : "");
		if (!signals.login.value.username && isMainland() && !senderCode) {
			await showDialog(t("requireLoginToSendFiles"));
			logIn();
			return;
		}
		const captchaResponse = await showCaptcha(action);
		signals.isLoadingShown.value = true;
		try {
			const data = await api.sendText(
				inputValue,
				captchaResponse,
				senderCode,
			);
			if (data.error) {
				if (
					data.error === "captchaRequired" &&
					!globals.captchaRequiredFor.has(action)
				) {
					globals.captchaRequiredFor.add(action);
					void sendText(senderCode);
				} else {
					if (data.error === "invalidSenderCode") {
						signals.invalidSenderCodeAttempts.value++;
						data.alert = t("senderCodeInvalidOrExpired");
					}
					await showResponseDialog(
						data.alert || data.error,
						data.link,
					);
					signals.sendPage.value = SendPage.SEND_TEXT;
				}
			} else {
				signals.sendSuccessInfo.value = {
					code: data.code,
					key: data.key,
				};
				signals.sendPage.value = SendPage.SUCCESS;
				if (data.fetch) {
					void fetch(data.fetch);
				}
			}
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingShown.value = false;
		}
	};

	useEffect(() => {
		if (!isLink || currentPage !== TextAreaPage.TEXT_RECEIVED) {
			return;
		}
		api.checkUrlSafety(textNoNewLines)
			.then((data) => {
				if (data.alert) {
					setIsLinkDangerous(true);
					setWarning(data.alert);
				}
			})
			.catch(console.error);
	}, [currentPage, isLink, textNoNewLines]);

	useEffect(() => {
		const reportLinks = document.getElementsByClassName(
			"report-link",
		) as HTMLCollectionOf<HTMLAnchorElement>;
		for (const reportLink of reportLinks) {
			reportLink.onclick = (event): void => {
				event.preventDefault();
				if (!signals.login.value.username) {
					logIn();
					return;
				}
				signals.receivePage.value = ReceivePage.REPORT;
			};
		}
	}, []);

	useSignalEffect(() => {
		if (
			currentPage === TextAreaPage.TEXT_RECEIVED_LOCALLY &&
			signals.currentFile.value?.text
		) {
			setInputValue(signals.currentFile.value.text);
		}
	});

	useSignalEffect(() => {
		setInputValue(defaultText.value);
	});

	return (
		<>
			<h1 className="popup-title">
				<label htmlFor={id}>{title}</label>
			</h1>
			{warning && (
				<div
					className={clsx("warning", styles["warning"])}
					dangerouslySetInnerHTML={{
						__html: warning,
					}}
				></div>
			)}
			<textarea
				className={clsx(
					styles["text-area"],
					!!warning && styles["has-warning"],
				)}
				data-autofocus={
					(currentPage === TextAreaPage.SEND_TEXT ||
						currentPage === TextAreaPage.SEND_TEXT_LOCALLY) &&
					!signals.currentFile.value?.text
				}
				id={id}
				placeholder={
					currentPage === TextAreaPage.TEXT_RECEIVED
						? ""
						: t("enterPlainTextHere")
				}
				value={inputValue}
				onChange={handleTextAreaChange}
			/>
			<button
				className="popup-main-button"
				type="button"
				onClick={handleButtonClick}
			>
				{buttonText}
			</button>
		</>
	);
}

export default TextArea;
