import PopupShell from "@/components/popup-shell";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { closePopup } from "@/utils";
import { JSX, useState } from "react";

interface ArticlePopupProps {
	isOpen: boolean;
}

function ArticlePopup({ isOpen }: ArticlePopupProps): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeArticlePopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			closePopup("article");
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	let url = signals.article.value?.link;
	if (url && globals.isDark) {
		url = url + (url.includes("?") ? "&" : "?") + "theme=dark";
	}

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeArticlePopup}
		>
			<div className="popup-content">
				<h1 className="popup-title">{signals.article.value?.alt}</h1>
				<iframe
					src={url}
					title={signals.article.value?.alt}
				></iframe>
			</div>
		</PopupShell>
	);
}

export default ArticlePopup;
