import styles from "@/components/notification-float.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { handleKeyboardClick } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, MouseEvent } from "react";

function NotificationFloat(): JSX.Element {
	const closeNotification = (event?: MouseEvent<HTMLButtonElement>): void => {
		event?.stopPropagation();
		globals.notificationCallbacks.onClose?.();
		globals.notificationCallbacks.onClose = null;
		globals.notificationCallbacks.onClick = null;
		signals.notification.value = null;
	};

	const handleClick = (): void => {
		globals.notificationCallbacks.onClick?.();
		closeNotification();
	};

	if (
		!signals.notification.value?.text ||
		!signals.notification.value?.title
	) {
		return <></>;
	}
	return (
		<div
			className={clsx(styles["notification-float"], "fade-in")}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleKeyboardClick(handleClick)}
		>
			<div className={styles["icon"]}></div>
			<div className={styles["content"]}>
				<h1>{signals.notification.value.title}</h1>
				<div className={styles["description"]}>
					{signals.notification.value.text?.replace(/\r|\n/g, "")}
				</div>
			</div>
			<button
				type="button"
				onClick={closeNotification}
			>
				{t("close")}
			</button>
		</div>
	);
}

export default NotificationFloat;
