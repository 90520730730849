import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import {
	DialogInfo,
	PosterInfo,
	SendPage,
	SettingsInfo,
	WindowExt,
} from "@/types";
import {
	faFile,
	faFileAudio,
	faFileCode,
	faFileCsv,
	faFileExcel,
	faFileImage,
	faFileLines,
	faFilePdf,
	faFilePowerpoint,
	faFileVideo,
	faFileWord,
	faFileZipper,
	IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import md5 from "md5";
import { JSX } from "react";
import { renderToStaticMarkup } from "react-dom/server";

export function addFiles(files: File[]): void {
	if (files.length === 0) {
		return;
	}
	globals.pendingUploadFiles = files;
	globals.sendCallback = (): void => {
		signals.sendPage.value = SendPage.FILE_LIST;
	};
	signals.pendingUploadFiles.value = files.map((file) => {
		return {
			name: file.name,
			size: file.size,
			type: file.type,
		};
	});
	signals.sendPage.value = SendPage.AGREEMENT;
	openPopup("send");
}

export function clearPathname(): void {
	if (
		window.location.pathname.length <= 1 ||
		globals.isTencent ||
		navigator.userAgent.includes("HeyTapBrowser") ||
		navigator.userAgent.includes("HuaweiBrowser")
	) {
		return;
	}
	replacePathname("/");
}

export function closePopup(key: string): void {
	signals.arePopupsShown.value = {
		...signals.arePopupsShown.value,
		[key]: false,
	};
	for (const value of Object.values(signals.arePopupsShown.value)) {
		if (value) {
			return;
		}
	}
	signals.isMainBoxShown.value = true;
}

export async function copyText(text: string): Promise<void> {
	try {
		if (!navigator.clipboard) {
			throw new Error("Clipboard not supported");
		}
		await navigator.clipboard.writeText(text);
		signals.toast.value = {
			text: t("copiedToClipboard"),
		};
	} catch {
		void showDialog(t("clipboardNotSupposed"), {
			defaultText: text,
			isPrompt: true,
		});
	}
}

export function encodeData(data: object): string {
	const array: string[] = [];
	for (const key in data) {
		const value = data[key as keyof typeof data];
		if (value) {
			array.push(key + "=" + encodeURIComponent(value));
		}
	}
	return array.join("&");
}

export function getFileIcon(fileType?: string): IconDefinition {
	let icon = faFile;
	if (!fileType) {
		return icon;
	}
	if (fileType.includes("image")) {
		icon = faFileImage;
	} else if (fileType.includes("word")) {
		icon = faFileWord;
	} else if (fileType.includes("presentation")) {
		icon = faFilePowerpoint;
	} else if (fileType.includes("spreadsheet")) {
		icon = faFileExcel;
	} else if (fileType.includes("pdf")) {
		icon = faFilePdf;
	} else if (fileType.includes("zip")) {
		icon = faFileZipper;
	} else if (fileType.includes("video")) {
		icon = faFileVideo;
	} else if (fileType.includes("audio")) {
		icon = faFileAudio;
	} else if (
		fileType.includes("css") ||
		fileType.includes("html") ||
		fileType.includes("javascript") ||
		fileType.includes("json")
	) {
		icon = faFileCode;
	} else if (fileType.includes("csv")) {
		icon = faFileCsv;
	} else if (fileType.includes("text")) {
		icon = faFileLines;
	}
	return icon;
}

export function getHelp(): void {
	window.open(
		"https://support.retiehe.com/?" +
			encodeData({
				app: globals.APP_NAME.toLowerCase(),
				isapp: globals.isApp ? "1" : undefined,
				username: signals.login.value.username,
			}),
	);
}

export function getRandomInteger(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function handleKeyboardClick(
	onClick: () => void,
): (event: KeyboardEvent | React.KeyboardEvent<Element>) => void {
	return (event: KeyboardEvent | React.KeyboardEvent<Element>): void => {
		switch (event.key) {
			case "Enter":
			case " ": {
				onClick();
				event.preventDefault();
				break;
			}
			default:
				break;
		}
	};
}

export function handleThemeChange(): void {
	if (globals.isDark) {
		document.documentElement.classList.add("dark");
	} else {
		document.documentElement.classList.remove("dark");
	}
	if (globals.isApp) {
		const themeMatch = matchMedia("(prefers-color-scheme: dark)");
		if (
			globals.isDark === themeMatch.matches &&
			(globals.isMac || desktopApp.isMicaSupported())
		) {
			document.body.classList.add("transparent");
		} else {
			document.body.classList.remove("transparent");
		}
	}
}

export function isMainland(): boolean {
	const result =
		!globals.dynamicInfo.region || globals.dynamicInfo.region === "CN";
	if (!result) {
		return globals.isChinaTimeZone && navigator.language.endsWith("-CN");
	}
	return result;
}

export function loadJs(src: string): Promise<void> {
	return new Promise((resolve, reject): void => {
		window.setTimeout((): void => {
			const newScript = document.createElement("script");
			newScript.src = src;
			newScript.onerror = reject;
			newScript.onload = (): void => {
				resolve();
			};
			document.body.appendChild(newScript);
		}, 1);
	});
}

export function logIn(): void {
	openPopup("login");
}

export function mergeSettings(newSettings: SettingsInfo): void {
	signals.settings.value = { ...signals.settings.value, ...newSettings };
}

export function openAccount(
	page: string,
	extra: Record<string, string> = {},
): void {
	const encodeToken = (): string => {
		if (!signals.login.value.token) {
			return "";
		}
		const preEncodedToken = [
			signals.login.value.token,
			md5(globals.dynamicInfo.publicIp || ""),
			Math.round(Date.now() / 1000),
		].join(",");
		let postEncodedToken = "";
		for (let i = 0; i < preEncodedToken.length; i++) {
			postEncodedToken += String.fromCharCode(
				preEncodedToken.charCodeAt(i) + 23,
			);
		}
		return window.btoa(postEncodedToken).replace(/=+$/, "");
	};

	const params = {
		isapp: globals.isApp ? "1" : undefined,
		service: globals.APP_NAME.toLowerCase(),
	} as Record<string, string>;
	for (const key in extra) {
		params[key] = extra[key];
	}
	let url = `https://account.retiehe.com/${page}?${encodeData(params)}`;
	if (signals.login.value.username) {
		url =
			globals.BACKEND +
			"/sso?" +
			encodeData({
				callback: url,
				token: encodeToken(),
				username: signals.login.value.username,
			});
	}
	window.open(url);
}

export function openArticle(newArticle: PosterInfo): void {
	signals.article.value = newArticle;
	signals.isMainBoxShown.value = false;
	signals.arePopupsShown.value = {
		...signals.arePopupsShown.value,
		article: true,
	};
}

export function openPopup(key: string): void {
	signals.isMainBoxShown.value = false;
	signals.arePopupsShown.value = {
		...signals.arePopupsShown.value,
		[key]: true,
	};
}

export function replacePathname(pathname: string): void {
	if (globals.isApp) {
		return;
	}
	window.history.replaceState(null, "", pathname);
}

export async function requireApp(
	text = t("thisFeatureRequiresApp"),
): Promise<void> {
	await showDialog(text, { showCancel: true });
	if (globals.dynamicInfo.appDlLink) {
		window.open(globals.dynamicInfo.appDlLink);
	}
}

export function roundToDecimal(value: number, decimal: number): number {
	const multiplier = Math.pow(10, decimal);
	return Math.round(value * multiplier) / multiplier;
}

export function showCaptcha(action: string): Promise<CaptchaResponse> {
	return new Promise<CaptchaResponse>((resolve) => {
		if (
			!globals.captchaRequiredFor.has(action) &&
			!action.endsWith("WithSenderCode")
		) {
			resolve({});
			return;
		}
		const windowExt = window as unknown as WindowExt;
		if (windowExt.TencentCaptcha) {
			const captcha = new windowExt.TencentCaptcha(
				"2040680418",
				(captchaResponse) => {
					if (captchaResponse.ret !== 0 || !captchaResponse.ticket) {
						return;
					}
					resolve(captchaResponse);
				},
			);
			captcha.show();
		} else {
			globals.captchaCallback = resolve;
			signals.isCaptchaShown.value = true;
		}
	});
}

export function showDialog(
	text: string | JSX.Element,
	options: DialogInfo = {},
	callbacks?: typeof globals.dialogCallbacks,
): Promise<string | undefined> {
	return new Promise<string | undefined>((resolve) => {
		if (callbacks) {
			globals.dialogCallbacks = callbacks;
		}
		if (!globals.dialogCallbacks.ok) {
			if (options.isPrompt) {
				globals.dialogCallbacks.ok = (_event, inputValue): void => {
					resolve(inputValue);
				};
			} else {
				globals.dialogCallbacks.ok = (): void => {
					resolve(undefined);
				};
			}
		}
		if (typeof text === "string") {
			options.text = text;
		} else {
			options.html = renderToStaticMarkup(text);
		}
		signals.dialog.value = options;
	});
}

export async function showResponseDialog(
	text: string,
	link?: string,
): Promise<void> {
	if (link) {
		await showDialog(text, {
			showCancel: true,
		});
		window.open(link);
	} else {
		await showDialog(text);
	}
}

export function sleep(delay: number): Promise<void> {
	return new Promise((resolve) => {
		window.setTimeout(resolve, delay);
	});
}

export function toGB(bytes: number): string {
	return (bytes / 1073741824).toFixed(2);
}

export async function uninstallServiceWorker(): Promise<void> {
	if (!navigator.serviceWorker) {
		return;
	}
	const registrations = await navigator.serviceWorker.getRegistrations();
	for (const registration of registrations) {
		await registration.unregister();
	}
	console.log("Service workers uninstalled.");
}

export function updateProgressPercent(newPercent: number): void {
	if (!signals.progress.value) {
		signals.progress.value = {
			percent: 0,
		};
	}
	signals.progress.value = {
		...signals.progress.value,
		percent: newPercent,
	};
}
