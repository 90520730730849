import SettingsItem from "@/components/settings-item";
import WifiTransferButtons from "@/components/wifi-transfer-buttons";
import styles from "@/components/wifi-transfer-dashboard.module.css";
import * as desktopApp from "@/desktop-app";
import * as signals from "@/signals";
import { MenuItemInfo, WifiTransferPage } from "@/types";
import { showDialog } from "@/utils";
import {
	faCloudArrowDown,
	faEnvelope,
	faFile,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { JSX, useEffect, useState } from "react";

interface WifiTransferDashboardProps {
	isClipboardSyncEnabled: boolean;
	setIsClipboardSyncEnabled: (newValue: boolean) => void;
}

function WifiTransferDashboard({
	isClipboardSyncEnabled,
	setIsClipboardSyncEnabled,
}: WifiTransferDashboardProps): JSX.Element {
	const [localIp, setLocalIp] = useState<string>("");
	const [wifiName, setWifiName] = useState<string>("");

	const checkPrerequisites = (): boolean => {
		if (signals.wifiTransferServerIp.value) {
			return desktopApp.isWifiTransferClientApproved();
		} else {
			return desktopApp.hasWifiTransferClients();
		}
	};

	const buttons: readonly MenuItemInfo[] = [
		{
			hasPopup: true,
			icon: faFile,
			label: t("sendFile"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				desktopApp.sendFilesLocally(
					signals.wifiTransferServerIp.value
						? [signals.wifiTransferServerIp.value]
						: undefined,
				);
			},
			when: true,
		},
		{
			hasPopup: false,
			icon: faEnvelope,
			label: t("sendText"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				signals.wifiTransferPage.value = WifiTransferPage.SEND_TEXT;
			},
			when: true,
		},
		{
			hasPopup: true,
			icon: faCloudArrowDown,
			label: t("receiveFile"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				desktopApp.receiveFilesLocally();
				void showDialog(t("selectFileOnAnotherDevice"));
			},
			when: true,
		},
	];

	const handleSyncClipboardChange = (newValue: boolean): void => {
		if (newValue) {
			if (!checkPrerequisites()) {
				return;
			}
			desktopApp.startClipboardSync();
		} else {
			desktopApp.stopClipboardSync();
		}
		setIsClipboardSyncEnabled(newValue);
	};

	useEffect(() => {
		setLocalIp(desktopApp.getLocalIp());
		desktopApp
			.getWifiName()
			.then((newWifiName) => {
				setWifiName(newWifiName);
			})
			.catch(console.error);
	}, []);

	return (
		<>
			<h1 className="popup-title">{t("wifiTransfer")}</h1>
			<div className={styles["info-container"]}>
				<div>
					{t("localIp")}
					<span>{localIp}</span>
				</div>
				<div>
					{t("currentWifi")}
					<span>{wifiName || t("unknown")}</span>
				</div>
				{signals.wifiTransferServerIp.value && (
					<div>
						{t("joined")}
						<span>{signals.wifiTransferServerIp.value}</span>
					</div>
				)}
			</div>
			<WifiTransferButtons buttons={buttons} />
			<SettingsItem
				checked={isClipboardSyncEnabled}
				label={t("syncClipboard")}
				setChecked={handleSyncClipboardChange}
				when={true}
			/>
		</>
	);
}

export default WifiTransferDashboard;
