import styles from "@/components/receive-code-input.module.css";
import * as signals from "@/signals";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, JSX, RefObject, useId } from "react";

interface ReceivePasswordInputProps {
	inputRef: RefObject<HTMLInputElement | null>;
	receiveFile: (code: string) => void;
}

function ReceivePasswordInput({
	inputRef,
	receiveFile,
}: ReceivePasswordInputProps): JSX.Element {
	const id = useId();

	const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		signals.password.value = event.target.value;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		if (signals.password.value) {
			receiveFile(signals.code.value);
		} else {
			inputRef.current?.focus();
		}
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterPassword")}</label>
				<div className="tip">{t("uploaderOfThisFileSetPassword")}</div>
			</div>
			<div>
				<input
					className={clsx("input", styles["receive-input"])}
					data-autofocus
					data-testid="password-input"
					id={id}
					ref={inputRef}
					autoComplete="off"
					value={signals.password.value}
					onChange={handleCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default ReceivePasswordInput;
