import LogoBox from "@/components/logo-box";
import styles from "@/components/main-box.module.css";
import SendContextMenu from "@/components/send-context-menu";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { ReceivePage, SendPage } from "@/types";
import { addFiles, openPopup, showDialog } from "@/utils";
import { Dialog, DialogPanel } from "@headlessui/react";
import clsx from "clsx";
import { t } from "i18next";
import {
	CSSProperties,
	ChangeEvent,
	JSX,
	MouseEvent,
	useRef,
	useState,
} from "react";
import { LongPressEventType, useLongPress } from "use-long-press";

interface MainBoxProps {
	getRecentFile: () => Promise<void>;
}

function MainBox({ getRecentFile }: MainBoxProps): JSX.Element {
	const [contextMenuLocation, setContextMenuLocation] = useState<
		CSSProperties | undefined
	>(undefined);
	const [isClosingContextMenu, setIsClosingContextMenu] =
		useState<boolean>(false);
	const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

	const longPressOptions = {
		detect: LongPressEventType.Touch,
		threshold: 500,
	};
	const receiveLongPress = useLongPress(() => {
		if (!globals.isIos) {
			return;
		}
		showMyFiles();
	}, longPressOptions);
	const sendLongPress = useLongPress(() => {
		if (!globals.isIos) {
			return;
		}
		sendText();
	}, longPressOptions);

	const contextMenuTimeout = useRef<number | undefined>(undefined);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const closeContextMenu = (): void => {
		setIsClosingContextMenu(true);
		contextMenuTimeout.current = window.setTimeout(() => {
			setShowContextMenu(false);
			setContextMenuLocation(undefined);
			setIsClosingContextMenu(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const filterFiles = (files: FileList, skipUnknown: boolean): File[] => {
		const results: File[] = [];
		let skipped = false;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.size > 0) {
				if (files.length === 1 || file.type || !skipUnknown) {
					results.push(file);
				} else {
					skipped = true;
				}
			}
		}
		if (results.length === 0) {
			if (skipUnknown && skipped) {
				filterFiles(files, false);
			} else {
				void showDialog(t("noPermissionToReadThisFile"));
			}
		}
		return results;
	};

	const handleContextMenu = (event: MouseEvent<HTMLDivElement>): void => {
		event.preventDefault();
		signals.isMenuShown.value = true;
	};

	const handleFileInputChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		if (!event.target.files) {
			return;
		}
		const files = filterFiles(event.target.files, true);
		addFiles(files);
	};

	const handleReceiveClick = (): void => {
		signals.receivePage.value = ReceivePage.CODE;
		openPopup("receive");
		void getRecentFile();
	};

	const handleReceiveContextMenu = (event: MouseEvent): void => {
		event.preventDefault();
		showMyFiles();
	};

	const handleSendContextMenu = (event: MouseEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		if (globals.isMobile) {
			sendText();
			return;
		}
		setContextMenuLocation({
			left: event.pageX + "px",
			top: event.pageY + "px",
		});
		setShowContextMenu(true);
	};

	const handleSendMouseEnter = (): void => {
		if (
			globals.isMobile ||
			window.innerWidth <= 500 ||
			contextMenuLocation
		) {
			return;
		}
		window.clearTimeout(contextMenuTimeout.current);
		setIsClosingContextMenu(false);
		setShowContextMenu(true);
	};

	const handleSendMouseLeave = (): void => {
		if (
			globals.isMobile ||
			window.innerWidth <= 500 ||
			contextMenuLocation
		) {
			return;
		}
		contextMenuTimeout.current = window.setTimeout(closeContextMenu, 100);
	};

	const sendFile = (): void => {
		if (!fileInputRef.current) {
			return;
		}
		closeContextMenu();
		fileInputRef.current.webkitdirectory = false;
		fileInputRef.current.click();
	};

	const sendText = (): void => {
		signals.sendPage.value = SendPage.SEND_TEXT;
		openPopup("send");
	};

	const showMyFiles = (): void => {
		signals.receivePage.value = ReceivePage.MY_FILES;
		openPopup("receive");
	};

	return (
		<div
			className={clsx(styles["main-box"], "fade-in")}
			onContextMenu={handleContextMenu}
		>
			<LogoBox className={styles["home-logo-box"]} />
			<button
				aria-haspopup="dialog"
				className={styles["major-button"]}
				onClick={sendFile}
				onContextMenu={handleSendContextMenu}
				onMouseEnter={handleSendMouseEnter}
				onMouseLeave={handleSendMouseLeave}
				type="button"
				{...sendLongPress()}
			>
				{t("send")}
			</button>
			<button
				aria-haspopup="dialog"
				className={styles["minor-button"]}
				onClick={handleReceiveClick}
				onContextMenu={handleReceiveContextMenu}
				type="button"
				{...receiveLongPress()}
			>
				{t("receive")}
			</button>
			<input
				hidden={true}
				multiple={true}
				onChange={handleFileInputChange}
				ref={fileInputRef}
				type="file"
			/>
			{showContextMenu && !contextMenuLocation && (
				<SendContextMenu
					className={clsx(
						"fade-in",
						isClosingContextMenu && "fade-out",
					)}
					fileInputRef={fileInputRef}
					onMouseEnter={handleSendMouseEnter}
					onMouseLeave={handleSendMouseLeave}
					onRequestClose={closeContextMenu}
					sendFile={sendFile}
				/>
			)}
			<Dialog
				onClose={closeContextMenu}
				open={showContextMenu && !!contextMenuLocation}
			>
				<div className="overlay">
					<DialogPanel role="menu">
						<SendContextMenu
							className={clsx(isClosingContextMenu && "fade-out")}
							fileInputRef={fileInputRef}
							style={contextMenuLocation}
							onRequestClose={closeContextMenu}
							sendFile={sendFile}
						/>
					</DialogPanel>
				</div>
			</Dialog>
		</div>
	);
}

export default MainBox;
