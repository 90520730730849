import styles from "@/components/countdown.module.css";
import { JSX, useEffect, useState } from "react";

interface CountdownProps {
	callback: () => void;
	seconds: number;
	seed?: unknown;
}

function Countdown({ callback, seconds, seed }: CountdownProps): JSX.Element {
	const [countdown, setCountdown] = useState<number>(seconds);

	useEffect(() => {
		const timeout = window.setTimeout(() => {
			setCountdown(countdown - 1);
		}, 1000);
		return (): void => {
			window.clearTimeout(timeout);
		};
	}, [countdown]);

	useEffect(() => {
		if (countdown <= 0) {
			callback();
		}
	}, [callback, countdown]);

	useEffect(() => {
		setCountdown(seconds);
	}, [seconds, seed]);

	return <span className={styles["countdown"]}>{countdown}</span>;
}

export default Countdown;
