import styles from "@/components/top-warning.module.css";
import * as signals from "@/signals";
import { handleKeyboardClick, showDialog } from "@/utils";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useState } from "react";

function TopWarning(): JSX.Element {
	const [isAgreed, setIsAgreed] = useState<boolean>(
		!!localStorage.getItem("Agree"),
	);

	const handleClick = (): void => {
		void showAgreement();
	};

	const showAgreement = async (): Promise<void> => {
		await showDialog(
			<>
				<span
					dangerouslySetInnerHTML={{
						__html: t("illegalFilesWarning"),
					}}
				></span>
				<br />
				<br />
				{t("reportEmail")}
				<a
					className="link"
					href="mailto:support@mail.retiehe.com"
				>
					support@mail.retiehe.com
				</a>
			</>,
			{
				okText: t("agree"),
				title: t("warning"),
			},
		);
		localStorage.setItem("Agree", Date.now().toString());
		setIsAgreed(true);
	};

	return (
		<div
			aria-haspopup="dialog"
			className={clsx(
				styles["top-warning"],
				(isAgreed || !!signals.wallpaperUrl.value) && styles["lighten"],
			)}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleKeyboardClick(handleClick)}
		>
			<FontAwesomeIcon icon={faExclamationTriangle} />
			<span>{t("illegalFilesNotAllowed")}</span>
		</div>
	);
}

export default TopWarning;
