import styles from "@/components/popup-shell.module.css";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Dialog } from "@headlessui/react";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, ReactNode } from "react";

interface PopupShellProps {
	children: ReactNode;
	countdown?: ReactNode;
	isClosing: boolean;
	isMaximized?: boolean;
	isOpen: boolean;
	onRequestClose?: () => void;
}

function PopupShell({
	children,
	countdown,
	isClosing,
	isMaximized,
	isOpen,
	onRequestClose,
}: PopupShellProps): JSX.Element {
	return (
		<Dialog
			onClose={onRequestClose || ((): void => {})}
			open={isOpen}
		>
			<div
				className={clsx(
					styles["popup-overlay"],
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<div
					className={clsx(
						styles["popup"],
						isMaximized && styles["maximized"],
					)}
				>
					{children}
					{countdown}
					{!!onRequestClose && (
						<CloseButton
							className={styles["close-button"]}
							title={t("close")}
							onClick={onRequestClose}
						>
							<FontAwesomeIcon icon={faClose} />
						</CloseButton>
					)}
				</div>
			</div>
		</Dialog>
	);
}

export default PopupShell;
