import PopupShell from "@/components/popup-shell";
import Slides from "@/components/slides";
import TextArea from "@/components/text-area";
import WifiTransferDashboard from "@/components/wifi-transfer-dashboard";
import WifiTransferDeviceList from "@/components/wifi-transfer-device-list";
import WifiTransferMode from "@/components/wifi-transfer-mode";
import * as desktopApp from "@/desktop-app";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { TextAreaPage, WifiTransferPage } from "@/types";
import { closePopup } from "@/utils";
import { t } from "i18next";
import { JSX, useState } from "react";

interface WifiTransferPopupProps {
	isOpen: boolean;
}

function WifiTransferPopup({ isOpen }: WifiTransferPopupProps): JSX.Element {
	const [isClipboardSyncEnabled, setIsClipboardSyncEnabled] =
		useState<boolean>(desktopApp.isClipboardSyncEnabled());
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeWifiTransferPopup = (): void => {
		if (signals.wifiTransferPage.value > WifiTransferPage.DASHBOARD) {
			signals.currentFile.value = null;
			signals.wifiTransferPage.value = WifiTransferPage.DASHBOARD;
			return;
		}
		setIsClosing(true);
		window.setTimeout(() => {
			signals.wifiTransferServerIp.value = "";
			closePopup("wifiTransfer");
			setIsClosing(false);
			if (desktopApp.closeWifiTransfer()) {
				signals.toast.value = {
					text: t("wifiTransferStopped"),
				};
			}
		}, globals.ANIMATION_WAIT_TIME);
	};

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeWifiTransferPopup}
		>
			<Slides currentPage={signals.wifiTransferPage.value}>
				<WifiTransferMode
					setIsClipboardSyncEnabled={setIsClipboardSyncEnabled}
				/>
				<WifiTransferDeviceList
					closeWifiTransferPopup={closeWifiTransferPopup}
					setIsClipboardSyncEnabled={setIsClipboardSyncEnabled}
				/>
				<WifiTransferDashboard
					isClipboardSyncEnabled={isClipboardSyncEnabled}
					setIsClipboardSyncEnabled={setIsClipboardSyncEnabled}
				/>
				<TextArea currentPage={TextAreaPage.SEND_TEXT_LOCALLY} />
				<TextArea currentPage={TextAreaPage.TEXT_RECEIVED_LOCALLY} />
			</Slides>
		</PopupShell>
	);
}

export default WifiTransferPopup;
