import styles from "@/components/send-agreement.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { SendPage } from "@/types";
import { handleKeyboardClick, isMainland } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

interface SendAgreementProps {
	closeSendPopup: () => void;
}

function SendAgreement({ closeSendPopup }: SendAgreementProps): JSX.Element {
	const handleAgreeClick = (): void => {
		if (signals.login.value.username || !isMainland()) {
			void globals.sendCallback?.();
		} else {
			signals.sendPage.value = SendPage.ID_SELECTOR;
		}
	};

	return (
		<>
			<h1 className="popup-title">{t("warning")}</h1>
			<div
				className={clsx(
					"content-left-aligned",
					styles["agreement-container"],
				)}
				dangerouslySetInnerHTML={{
					__html: t("illegalFilesWarning"),
				}}
			></div>
			<div className={styles["button-bar"]}>
				<button
					className="popup-main-button"
					data-autofocus
					type="button"
					onClick={handleAgreeClick}
				>
					{t("agreeAndContinue")}
				</button>
				<span
					className="link"
					role="button"
					tabIndex={0}
					onClick={closeSendPopup}
					onKeyDown={handleKeyboardClick(closeSendPopup)}
				>
					{t("cancelUpload")}
				</span>
			</div>
		</>
	);
}

export default SendAgreement;
