import styles from "@/components/send-id-provider.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { SendPage } from "@/types";
import { handleKeyboardClick, logIn, showDialog } from "@/utils";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

function SendIdProvider(): JSX.Element {
	const selectPersonalDevice = (): void => {
		if (signals.login.value.username) {
			void globals.sendCallback?.();
		} else {
			logIn();
		}
	};

	const selectPublicDevice = (): void => {
		signals.sendPage.value = SendPage.SENDER_CODE;
	};

	const whyLogin = (): void => {
		void showDialog(
			"1. 根据《中华人民共和国网络安全法》等法律法规和监管部门要求，发送内容必须实名登录；" +
				"2. 为配合执法部门打击违法违规内容犯罪，我们必须记录上传者实名信息；" +
				"3. 登录后，您可享受云端同步、多端协同等便捷功能；" +
				"4. 如果您正在使用公共设备，不想在该设备输入账号和密码，可以选择第二个选项。感谢您的理解！",
			{
				title: t("whyLogin"),
			},
		);
	};

	useSignalEffect(() => {
		if (
			signals.sendPage.value === SendPage.ID_SELECTOR &&
			signals.login.value.username
		) {
			void globals.sendCallback?.();
		}
	});

	return (
		<>
			<h1 className="popup-title">{t("chooseIdentityProvider")}</h1>
			<div className={styles["id-provider-list"]}>
				<button
					className={clsx(
						styles["option"],
						styles["personal-device"],
					)}
					type="button"
					onClick={selectPersonalDevice}
				>
					<div className={styles["icon-container"]}>
						<FontAwesomeIcon
							icon={faUser}
							size="xl"
						/>
					</div>
					<div className={styles["text-container"]}>
						<div className={styles["title"]}>
							{t("thisIsPersonalDeviceWannaLogin")}
							<span className={styles["description"]}>
								{t("recommended")}
							</span>
						</div>
						<div className={styles["description"]}>
							{t("personalDeviceDescription")}
						</div>
					</div>
				</button>
				<button
					className={clsx(styles["option"], styles["public-device"])}
					type="button"
					disabled={signals.invalidSenderCodeAttempts.value > 2}
					onClick={selectPublicDevice}
				>
					<div className={styles["icon-container"]}>
						<FontAwesomeIcon
							icon={faUsers}
							size="xl"
						/>
					</div>
					<div className={styles["text-container"]}>
						<div className={styles["title"]}>
							{t("thisIsPublicDeviceDontWannaLogin")}
						</div>
						<div className={styles["description"]}>
							{t("publicDeviceDescription")}
						</div>
					</div>
				</button>
			</div>
			<span
				className={clsx("link", styles["link"])}
				role="button"
				tabIndex={0}
				onClick={whyLogin}
				onKeyDown={handleKeyboardClick(whyLogin)}
			>
				<FontAwesomeIcon
					className={styles["icon"]}
					icon={faCircleQuestion}
				/>
				{t("whyLogin")}
			</span>
		</>
	);
}

export default SendIdProvider;
