/* eslint-disable @typescript-eslint/explicit-function-return-type */

import Reader from "@/classes/reader";

export async function importAliOss() {
	return (await import("ali-oss")).default;
}

export async function importDocxtemplate() {
	return (await import("docxtemplater")).default;
}

export async function importLibarchive() {
	return (await import("libarchive.js")).Archive;
}

export async function importPizzip() {
	return (await import("pizzip")).default;
}

export async function importSemver() {
	return await import("semver");
}

export async function importTesseract() {
	return await import("tesseract.js");
}

export async function importXlsx() {
	return (await import("@e965/xlsx")).default;
}

export function loadDependencies(): void {
	window.setTimeout(() => {
		void importAliOss();
		void importDocxtemplate();
		void importLibarchive();
		void importPizzip();
		void importTesseract();
		void importXlsx();
		void Reader.getOcrWorker();
	}, 1000);
}
